// vendors
import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-content: center;

  min-width: 100%;

  z-index: 2;

  min-height: calc(
    100vh - var(--sst-timeline-height) - var(--sst-titlebar-height)
  );
`;
