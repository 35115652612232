// vendors
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Colors } from '@blueprintjs/core';
import styled from 'styled-components';
import 'styled-components/macro';
import { addToast } from '../../../States/Actions';

// components
import SubtitleCard from '../../../Components/SubtitleCard';
import LinedTextArea from '../../../Components/LinedTextArea';
import { useVideoPlayer } from '../../../Components/VideoPlayer';

// actions
import {
  createSubtitle,
  deleteSubtitle,
  editSubtitle,
  splitSubtitle,
  // seeking,
  // currentTime,
  mergeSubtitle,
  // selectSub,
  resetCaretPosition,
  modifyLastSubtitleColorChanged,
} from '../../../States/Actions';
import { useState } from 'react';

const OriginalLangContent = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-template-rows: 47px;
  grid-gap: 5px;
  align-items: center;
  justify-content: center;
  overflow: hidden auto;

  color: ${Colors.DARK_GRAY5};
  font-size: 0.8rem;
  line-height: 1.5;
  text-align: center;
  white-space: pre;
  cursor: not-allowed;

  opacity: ${({ $faded }) => ($faded ? 0.55 : 1)};
`;

const RenderSubtitleCard = ({
  subtitle,
  index,
  isActive,
  faded,
  subtitles,
  fps,
  isCaptionsForTv,
  isOneSentencePerCaption,
  isCustomerVersion
}) => {
  const dispatch = useDispatch();

  const { isPlaying, seekToSeconds } = useVideoPlayer();

  const checkColorChange = useCallback(
    (startTime, endTime) => {
      return endTime - startTime < 1 || endTime - startTime > 5 ? '#ff8080' : '';
    },
    []
  );

  const checkNextSubtitleTimings = useCallback(
    (value) => {
      const nextSubtitle = subtitles[index + 1];
      if (!nextSubtitle) return;
      if (value <= nextSubtitle.start && value < nextSubtitle.end) return;
      dispatch(
        addToast({
          message: `Make sure that the current subtitle timings are equal to or less than the timings of the following subtitle.`,
          timeout: 5000,
          intent: 'warning',
          icon: 'warning',
        })
      );
    },
    [dispatch, index, subtitles]
  );

  const handleStartTimeBlur = useCallback(
    (value) => {
      const newColor = checkColorChange(value, subtitle.end);
      checkNextSubtitleTimings(value);
      dispatch(editSubtitle(subtitle.id, { start: value, subtitleColor: newColor }));
      dispatch(modifyLastSubtitleColorChanged(subtitle));
    },
    [dispatch, subtitle, checkNextSubtitleTimings, checkColorChange]
  );

  const handleCreate = useCallback(() => {
    dispatch(createSubtitle(index));
  }, [dispatch, index]);

  const handleEndTimeBlur = useCallback(
    (value) => {
      const newColor = checkColorChange(subtitle.start, value);
      checkNextSubtitleTimings(value);
      dispatch(editSubtitle(subtitle.id, { end: value, subtitleColor: newColor }));
      dispatch(modifyLastSubtitleColorChanged(subtitle));
    },
    [dispatch, subtitle, checkNextSubtitleTimings, checkColorChange]
  );

  const handleDelete = useCallback(() => {
    dispatch(deleteSubtitle(subtitle.id));
  }, [dispatch, subtitle]);

  const handleSplitCard = useCallback(
    (first, last) => {
      dispatch(splitSubtitle(subtitle.id, first, last));
    },
    [dispatch, subtitle]
  );

  const handleMergeCard = useCallback(() => {
    dispatch(mergeSubtitle(subtitle.id));
  }, [dispatch, subtitle]);

  const handleChange = useCallback(
    (value) => {
      dispatch(editSubtitle(subtitle.id, { lines: value }));
    },
    [dispatch, subtitle]
  );

  const handleResetCaretPosition = useCallback(() => {
    dispatch(resetCaretPosition(subtitle.id));
  }, [dispatch, subtitle]);

  const handleSelectSub = useCallback(() => {
    if (isActive) return;

    seekToSeconds(subtitle.startTime.value + 0.0001);
  }, [isActive, seekToSeconds, subtitle.startTime]);

  const originalLangContent = useMemo(
    () => subtitle.originalLangContent?.join('\n'),
    [subtitle.originalLangContent]
  );

  return (
    <SubtitleCard
      style={{
        background: isActive ? 'rgb(235 241 245 / 25%)' : 'white',
      }}
      index={index}
      isActive={isActive}
      startTime={subtitle.startTime}
      endTime={subtitle.endTime}
      onFocus={handleSelectSub}
      onStartTimeBlur={handleStartTimeBlur}
      onEndTimeBlur={handleEndTimeBlur}
      onDelete={handleDelete}
      onCreateAfter={handleCreate}
      warnings={subtitle.corrections}
      isCaptionsForTv={isCaptionsForTv}
      fps={fps}
      wpm={subtitle.wpm}
      lines={subtitle.lines}
      subtitles={subtitles}
      subtitleColor={subtitle.subtitleColor}
    >
      {originalLangContent?.length > 0 ? (
        <OriginalLangContent faded={faded}>
          <div />
          <div>{originalLangContent}</div>
        </OriginalLangContent>
      ) : (
        <div />
      )}

      <LinedTextArea
        value={subtitle.lines}
        meta={subtitle.meta}
        caretPosition={subtitle.caretPosition}
        onClick={handleSelectSub}
        onChange={handleChange}
        onCreateAfter={handleCreate}
        onSplit={handleSplitCard}
        onMerge={handleMergeCard}
        onResetCaretPosition={handleResetCaretPosition}
        focused={isActive && !isPlaying}
        blurred={isActive && isPlaying}
        faded={faded}
        isCaptionsForTv={isCaptionsForTv}
        isOneSentencePerCaption={isOneSentencePerCaption}
        isCustomerVersion={isCustomerVersion}
      />
    </SubtitleCard>
  );
};

export default RenderSubtitleCard;
