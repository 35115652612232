import { addToast } from '.';
import Config from '../../Libs/Config';

// Legacy actions
export const MODIFY_SINGLE_CAPTION = 'MODIFY_SINGLE_CAPTION';
export const ADD_NEW_CAPTION = 'ADD_NEW_CAPTION';
export const ADD_NEW_CAPTION_BETWEEN = 'ADD_NEW_CAPTION_BETWEEN';
export const DELETE_CAPTION = 'DELETE_CAPTION';
export const CURRENTLY_SELECTED = 'CURRENTLY_SELECTED';
export const MODIFY_MULTIPLE_CAPTIONS = 'MODIFY_MULTIPLE_CAPTIONS';
export const CURRENT_TIME = 'CURRENT_TIME';
export const RESET_EDIT_SINCE_LAST_SAVED = 'RESET_EDIT_SINCE_LAST_SAVED';
// export const UPDATE_AUTOSAVE_COUNT = 'UPDATE_AUTOSAVE_COUNT';
// export const RESTORE_AUTOSAVE_COUNT = 'RESTORE_AUTOSAVE_COUNT';

// New actions
export const CREATE_SUBTITLE = 'CREATE_SUBTITLE';
export const CREATE_SUBTITLE_BETWEEN = 'CREATE_SUBTITLE_BETWEEN';
export const EDIT_SUBTITLE = 'EDIT_SUBTITLE';
export const DELETE_SUBTITLE = 'DELETE_SUBTITLE';
export const FETCH_SRT_DATA_BEGIN = 'FETCH_SRT_DATA_BEGIN';
export const FETCH_SRT_DATA_SUCCESS = 'FETCH_SRT_DATA_SUCCESS';
export const FETCH_SRT_DATA_FAILURE = 'FETCH_SRT_DATA_FAILURE';
export const SAVE_SRT_DATA_BEGIN = 'SAVE_SRT_DATA_BEGIN';
export const SAVE_SRT_DATA_SUCCESS = 'SAVE_SRT_DATA_SUCCESS';
export const SAVE_SRT_DATA_FAILURE = 'SAVE_SRT_DATA_FAILURE';
export const SPLIT_SUBTITLE = 'SPLIT_SUBTITLE';
export const MERGE_SUBTITLE = 'MERGE_SUBTITLE';
export const RESET_CARET_POSITION = 'RESET_CARET_POSITION';
export const MODIFY_LAST_SUBTITLE_COLOR_CHANGED = 'MODIFY_LAST_SUBTITLE_COLOR_CHANGED';

export async function fetchSrtData(dispatch) {
  try {
    const url = new URL(document.location);
    const ident = url.pathname.slice(1);

    dispatch({ type: FETCH_SRT_DATA_BEGIN });

    const response = await fetch(`${Config.apiUrl}/customer/get-srt`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify({ ident }),
    }).then((res) => res.json());

    if (response.status !== 'OK') {
      throw new Error(response.msg);
    }

    dispatch({
      type: FETCH_SRT_DATA_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    console.error(error);

    dispatch(
      addToast({
        message: `ERROR. The project couldn't be loaded.\n${error}`,
        timeout: 100000,
        intent: 'danger',
        icon: 'error',
      })
    );

    dispatch({ type: FETCH_SRT_DATA_FAILURE, payload: error });
  }
}

export async function saveSrtData(dispatch, getState) {
  try {
    if (
      getState().srtData.present.status === 'loading'
      || !getState().srtData.present.canSave
      || getState().srtData.present.savingStatus === 'loading'
    ) {
      dispatch(
        addToast({
          message: 'The project is currently being loaded or saved, please wait until the process is completed to do so again',
          timeout: 6000,
          intent: 'warning',
          icon: 'warning',
        })
      );
      return;
    }

    const url = new URL(document.location);
    const ident = url.pathname.slice(1);

    const {
      currentTime: videotimestamp,
      data,
      iscustomerversion,
      canSave,
    } = getState().srtData.present;

    const subtitles = data.subtitles.map((subtitle, index) => ({
      // start: subtitle.start,
      // end: subtitle.end,
      start: subtitle.startTime.value,
      end: subtitle.endTime.value,
      lines: subtitle.lines,
      originallangcontent: subtitle.originalLangContent || [],
      originallangcontentbubbleid: subtitle.originalLangContent
        ? index
        : undefined,
    }));

    for (let i = 0; i < subtitles.length; i++) {
      if (subtitles[i].lines.some(line => line.trim() === '')) {
        dispatch(
          addToast({
            message: `Line ${i + 1} is empty. Empty content is not allowed.`,
            timeout: 6000,
            intent: 'danger',
            icon: 'error',
          })
        );
        return;
      }
    }

    // if (process.env.NODE_ENV === 'development') return;

    dispatch({ type: SAVE_SRT_DATA_BEGIN });

    const response = await fetch(`${Config.apiUrl}/customer/save-srt`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify({
        ident,
        subtitles,
        videotimestamp,
        canSave,
      }),
    }).then((res) => res.json());

    if (response.status !== 'OK') {
      if (response.data && response.data.index) {
        const newCurrentTime = subtitles[Number(response.data.index)].start;
        dispatch(currentTime(newCurrentTime));
      }
      throw new Error(response.msg);
    }

    dispatch({ type: RESET_EDIT_SINCE_LAST_SAVED });

    if (!iscustomerversion) {
      const indexOfProblemInCharsPerLine =
        response.indexOfProblemInCharsPerLine;

      const indexOfProblemInLines = response.indexOfProblemInLines;
      const indexOfProblemWithTvDurationMax = response.indexOfProblemWithTvDurationMax;

      const indexOfProblemInWpm = response.indexOfProblemInWpm;

      const indexOfProblemWithTvDuration =
        response.indexOfProblemWithTvDuration;

      const isCaptionsForTv = response.isCaptionsForTv;
      const min1Second = response.min1Second;

      if (isCaptionsForTv && (indexOfProblemWithTvDurationMax > 0 || indexOfProblemWithTvDuration > 0 || indexOfProblemInWpm > 0)) {
        const cells = [
          { index: indexOfProblemWithTvDurationMax, message: 'the cell duration is greater than 5 seconds (maximum for TV captions). Please decrease the cell duration.' },
          { index: indexOfProblemWithTvDuration, message: 'the cell duration is less than 1 second (minimum for TV captions). Please increase the cell duration.' },
          { index: indexOfProblemInWpm, message: 'the words per minute quantity is higher than 325 (maximum for TV captions). Please reduce the number of words or increase the cell duration.' }
        ].filter(cell => cell.index > 0);

        if (cells.length > 0) {
          cells.sort((a, b) => a.index - b.index);
          const { index, message } = cells[0];

          dispatch(
            addToast({
              message: `The project has been saved successfully, but in the cell #${index} ${message}`,
              timeout: 6000,
              intent: 'warning',
              icon: 'warning',
            })
          );
        }
      } else if (indexOfProblemInCharsPerLine > 0) {
        dispatch(
          addToast({
            message: `The project has been saved successfully, but in the cell #${indexOfProblemInCharsPerLine} the number of lines or the number of chars per line exceeds the limit`,
            timeout: 6000,
            intent: 'warning',
            icon: 'warning',
          })
        );
      } else if (indexOfProblemInLines > 0) {
        dispatch(
          addToast({
            message: `The project has been saved successfully, but in the cell #${indexOfProblemInLines} the number of lines or the number of chars per line exceeds the limit`,
            timeout: 6000,
            intent: 'warning',
            icon: 'warning',
          })
        );
      } else if (min1Second && indexOfProblemWithTvDuration > 0) {
        dispatch(
          addToast({
            message: `The project has been saved successfully, but in the cell #${indexOfProblemWithTvDuration} the cell duration is less than 1 second. Please increase the cell duration.`,
            timeout: 6000,
            intent: 'warning',
            icon: 'warning',
          })
        );
      } else {
        dispatch(
          addToast({
            message: 'The project has been saved successfully.',
            timeout: 3000,
            intent: 'success',
            icon: 'tick',
          })
        );
      }
    } else {
      const isCaptionsForTv = response.isCaptionsForTv;
      const isOneSentencePerCaption = response.isOneSentencePerCaption;
      const indexOfProblemInCharsPerLineCaptionsForTV = response.indexOfProblemInCharsPerLineCaptionsForTV;
      const indexOfProblemInCharsPerLine = response.indexOfProblemInCharsPerLine;
      const indexOfProblemInLines = response.indexOfProblemInLines;
      const linesPerCaption = response.linesPerCaption;
      const charsPerLine = response.charsPerLine;

      if (isCaptionsForTv && indexOfProblemInCharsPerLineCaptionsForTV > 0 && isOneSentencePerCaption === false) {
        dispatch(
          addToast({
            message: `The project has been saved successfully, but in the cell #${indexOfProblemInCharsPerLineCaptionsForTV} you have exceded the limit of 32 character per line (maximum for TV captions). Reduce the length of the lines accordingly.`,
            timeout: 6000,
            intent: 'warning',
            icon: 'warning',
          })
        );
      } else if (indexOfProblemInCharsPerLine > 0 && isOneSentencePerCaption === false) {
        dispatch(
          addToast({
            message: `The project has been saved successfully, but the number of characters per line in the cell #${indexOfProblemInCharsPerLine} might need to be adjusted because it exceeds the limit of ${charsPerLine} characters`,
            timeout: 6000,
            intent: 'warning',
            icon: 'warning',
          })
        );
      } else if (indexOfProblemInLines > 0) {
        dispatch(
          addToast({
            message: `The project has been saved successfully, but the number of lines in the cell #${indexOfProblemInLines} might need to be adjusted because it exceeds the limit of ${linesPerCaption} lines`,
            timeout: 6000,
            intent: 'warning',
            icon: 'warning',
          })
        );
      } else {
        dispatch(
          addToast({
            message: 'The project has been saved successfully.',
            timeout: 3000,
            intent: 'success',
            icon: 'tick',
          })
        );
      }
    }

    dispatch({
      type: SAVE_SRT_DATA_SUCCESS,
    });
  } catch (error) {
    console.error(error);

    dispatch({ type: RESET_EDIT_SINCE_LAST_SAVED });

    dispatch(
      addToast({
        message: `ERROR. The project couldn't be saved.\n${error}`,
        timeout: 100000,
        intent: 'danger',
        icon: 'error',
      })
    );

    dispatch({ type: SAVE_SRT_DATA_FAILURE, payload: error });
  }
}

export const createSubtitle = (index) => (dispatch, getState) => {
  const subtitles = [...getState().srtData.present.data.subtitles];
  const currentSubtitle = subtitles[index];
  const duration = getState().srtData.present.duration;
  const lastSubtitleEndInSeconds =
    subtitles && subtitles[subtitles.length - 1].endTime.value
      ? subtitles[subtitles.length - 1].endTime.value
      : 0;

  const nextSubtitle =
    subtitles.length - 1 > index ? subtitles[index + 1] : null;

  if (currentSubtitle && nextSubtitle) {
    dispatch({ type: CREATE_SUBTITLE_BETWEEN, payload: { index } });
  } else {
    if (Math.ceil(lastSubtitleEndInSeconds) > duration - 1) {
      dispatch(
        addToast({
          message: `ERROR. There is not enough space to add another bubble. Please rearrange the timeline if you want to add another bubble.`,
          timeout: 10000,
          intent: 'danger',
          icon: 'error',
        })
      );
    } else {
      dispatch({ type: CREATE_SUBTITLE, payload: { index } });
    }
  }
};

export const editSubtitle = (id, subtitle) => (dispatch) => {
  dispatch({
    type: EDIT_SUBTITLE,
    payload: {
      id,
      data: { ...subtitle },
    },
  });
};

export const splitSubtitle = (id, first, last) => (dispatch) => {
  dispatch({
    type: SPLIT_SUBTITLE,
    payload: {
      index: id,
      data: {
        first,
        last,
      },
    },
  });
};

export const mergeSubtitle = (id) => (dispatch) => {
  dispatch({
    type: MERGE_SUBTITLE,
    payload: { id },
  });
};

export const deleteSubtitle = (id) => (dispatch) => {
  dispatch({
    type: DELETE_SUBTITLE,
    payload: { id },
  });
};

export const modifyMultipleCaption = (subtitles) => (dispatch) => {
  dispatch({
    type: MODIFY_MULTIPLE_CAPTIONS,
    payload: {
      subtitles,
    },
  });
};

// export const modifySingleCaption = (newCaption, subIndex) => {
//   return {
//     type: MODIFY_SINGLE_CAPTION,
//     subIndex,
//     newCaption,
//   };
// };

// export const addNewCaption = (oldCaption, newCaption, subIndex) => {
//   return {
//     type: ADD_NEW_CAPTION,
//     oldCaption,
//     subIndex,
//     newCaption,
//   };
// };

// export const addNewCaptionBetweenTwoCaption = (
//   backwardCaptionIndex,
//   forwardCaptionIndex
// ) => {
//   return {
//     type: ADD_NEW_CAPTION_BETWEEN,
//     backwardCaptionIndex,
//     forwardCaptionIndex,
//   };
// };

// export const deleteCaption = (subIndex) => {
//   return {
//     type: DELETE_CAPTION,
//     subIndex,
//   };
// };

export const selectSub = (subIndex) => {
  return {
    type: CURRENTLY_SELECTED,
    subIndex,
  };
};

export const currentTime = (time) => {
  return {
    type: CURRENT_TIME,
    time,
  };
};

export const resetEditSinceLastSaved = () => {
  return {
    type: RESET_EDIT_SINCE_LAST_SAVED,
  };
};

export const resetCaretPosition = (id) => (dispatch) => {
  dispatch({
    type: RESET_CARET_POSITION,
    payload: { id },
  });
};

export const modifyLastSubtitleColorChanged = (subtitle) => (dispatch) => {
  dispatch({
    type: MODIFY_LAST_SUBTITLE_COLOR_CHANGED,
    payload: {
      subtitle,
    },
  });
};

// export const updateAutosaveCount = () => {
//   return {
//     type: UPDATE_AUTOSAVE_COUNT,
//   };
// };

// export const restoreAutosaveCount = () => {
//   return {
//     type: RESTORE_AUTOSAVE_COUNT,
//   };
// };
