// export const PLAY_PAUSE = 'PLAY_PAUSE';
// export const SEEKING = 'SEEKING';
export const VERTICAL_ZOOM = 'VERTICAL_ZOOM';
export const VERTICAL_ZOOM_IN = 'VERTICAL_ZOOM_IN';
export const VERTICAL_ZOOM_OUT = 'VERTICAL_ZOOM_OUT';
export const HORIZONTAL_ZOOM = 'HORIZONTAL_ZOOM';
export const HORIZONTAL_ZOOM_IN = 'HORIZONTAL_ZOOM_IN';
export const HORIZONTAL_ZOOM_OUT = 'HORIZONTAL_ZOOM_OUT';
// export const IS_SEEKING = 'IS_SEEKING';
// export const IS_PLAYING = 'IS_PLAYING';
// export const PLAYBACK_RATE = 'PLAYBACK_RATE';
// export const INCREASE_PLAYBACK_RATE = 'INCREASE_PLAYBACK_RATE';
// export const DECREASE_PLAYBACK_RATE = 'DECREASE_PLAYBACK_RATE';

// export const seeking = (seekingTime) => {
//   return {
//     type: SEEKING,
//     seekingTime,
//   };
// };

export const setVerticalZoom = (newZoom) => {
  return {
    type: VERTICAL_ZOOM,
    newZoom,
  };
};

export const verticalZoomIn = () => {
  return {
    type: VERTICAL_ZOOM_IN,
  };
};

export const verticalZoomOut = () => {
  return {
    type: VERTICAL_ZOOM_OUT,
  };
};

export const setHorizontalZoom = (newZoom) => {
  return {
    type: HORIZONTAL_ZOOM,
    newZoom,
  };
};

export const horizontalZoomIn = () => {
  return {
    type: HORIZONTAL_ZOOM_IN,
  };
};

export const horizontalZoomOut = () => {
  return {
    type: HORIZONTAL_ZOOM_OUT,
  };
};

// export const setIsPlaying = (isPlaying) => {
//   return {
//     type: IS_PLAYING,
//     isPlaying,
//   };
// };

// export const setPlaybackRate = (playbackRate) => {
//   return {
//     type: PLAYBACK_RATE,
//     playbackRate,
//   };
// };

// export const increasePlaybackRate = () => {
//   return {
//     type: INCREASE_PLAYBACK_RATE,
//   };
// };

// export const decreasePlaybackRate = () => {
//   return {
//     type: DECREASE_PLAYBACK_RATE,
//   };
// };
