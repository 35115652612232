// vendors
import React, { useCallback, useContext, useRef, useState } from 'react';
import { useFullScreenHandle } from "react-full-screen";

export const VideoPlayerContext = React.createContext();

export const useVideoPlayer = () => useContext(VideoPlayerContext);

export const playbackRateOptions = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];

export const VideoPlayerProvider = ({ children }) => {
  const [playbackRate, setPlaybackRate] = useState(1);
  const [isReady, setIsReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(null);
  const [volume, setVolume] = useState(0.5);
  const [muted, setMuted] = useState(false);
  const [progress, setProgress] = useState({
    played: 0,
    playedSeconds: 0,
    loaded: 0,
    loadedSeconds: 0,
  });
  const [hideCaption, setHideCaption] = useState(false);
  const playerRef = useRef(null);
  const goFullScreen = useFullScreenHandle();
  const fullscreenElement = document.fullscreenElement;

  const toggleIsPlaying = useCallback(() => {
    setIsPlaying(!isPlaying);
  }, [isPlaying]);

  const increasePlaybackRate = useCallback(() => {
    const playbackRateIndex = playbackRateOptions.findIndex(
      (value) => value === playbackRate
    );

    if (playbackRateIndex === playbackRateOptions.length - 1) return;

    setPlaybackRate(playbackRateOptions[playbackRateIndex + 1]);
  }, [playbackRate]);

  const decreasePlaybackRate = useCallback(() => {
    const playbackRateIndex = playbackRateOptions.findIndex(
      (value) => value === playbackRate
    );

    if (playbackRateIndex === 0) return;

    setPlaybackRate(playbackRateOptions[playbackRateIndex - 1]);
  }, [playbackRate]);

  const seekToPercentage = useCallback(
    (fraction) => {
      setProgress((prev) => ({
        ...prev,
        played: fraction,
        // playedSeconds: (duration / fraction) * 100,
        playedSeconds: duration * fraction,
      }));

      playerRef.current.seekTo(fraction);
    },
    [setProgress, duration]
  );

  const seekToSeconds = useCallback(
    (secs) => {
      setProgress((prev) => ({
        ...prev,
        played: secs / duration,
        playedSeconds: secs,
      }));

      playerRef.current.seekTo(secs, 'seconds');
    },
    [duration, setProgress]
  );

  const seekForward = useCallback(
    (sec) => {
      if (progress.playedSeconds === duration) return;

      seekToSeconds(
        duration - sec > progress.playedSeconds
          ? progress.playedSeconds + sec
          : duration
      );
    },
    [duration, progress, seekToSeconds]
  );

  const seekBackward = useCallback(
    (sec) => {
      if (progress.playedSeconds === 0) return;

      seekToSeconds(
        sec < progress.playedSeconds ? progress.playedSeconds - sec : 0
      );
    },
    [progress, seekToSeconds]
  );

  const getDuration = useCallback(() => {
    return duration;
  }, [duration]);

  const handleFullScreen = useCallback(
    () => {
      fullscreenElement
        ? goFullScreen.exit()
        : goFullScreen.enter();
    },
    [goFullScreen, fullscreenElement]
  );

  // useEffect(() => console.log(progress.playedSeconds), [progress]);

  return (
    <VideoPlayerContext.Provider
      value={{
        playbackRate,
        isReady,
        isPlaying,
        progress,
        duration,
        volume,
        muted,
        hideCaption,
        setMuted,
        setVolume,
        setPlaybackRate,
        setIsReady,
        setIsPlaying,
        toggleIsPlaying,
        increasePlaybackRate,
        decreasePlaybackRate,
        seekToSeconds,
        seekToPercentage,
        setDuration,
        setProgress,
        seekForward,
        seekBackward,
        setHideCaption,
        getDuration,
        playerRef,
        goFullScreen,
        fullscreenElement,
        handleFullScreen,
      }}
    >
      {children}
    </VideoPlayerContext.Provider>
  );
};

export const withVideoPlayerProvider = (WrappedComponent) => (props) =>
  (
    <VideoPlayerProvider>
      <WrappedComponent {...props} />
    </VideoPlayerProvider>
  );
