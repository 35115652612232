import styled from 'styled-components';
import Timeline from '../../Sections/Timeline';

export const Container = styled.div`
  position: relative;

  display: grid;
  grid-template-columns: minmax(540px, 1fr) 2fr;
  grid-template-rows: 1fr auto;
  height: 100%;
`;

export const StyledTimeline = styled(Timeline)`
  grid-column: 1 / -1;
  grid-row: 2 / span 1;

  z-index: 10;

  /* box-shadow: rgb(0 0 0 / 12%) 0px -1px 2px 0px, rgb(0 0 0 / 15%) 0px -3px 4px 0px; */
  box-shadow: rgba(0, 0, 0, 0.12) 0px -1px 2px 0px,
    rgba(0, 0, 0, 0.2) 0px -2px 3px 0px;
`;
