import styled, { css } from 'styled-components';
import { Colors } from '@blueprintjs/core';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 2.2ch 1fr;
  /* grid-template-rows: 100px; */
  grid-gap: 5px;
  width: 100%;
  height: 100%;
  /* max-height: 50px; */
  /* padding: 2px; */
  /* padding-right: 1rem; */
  overflow: hidden;
`;

export const TextAreaWrapper = styled.div`
  height: 100%;
  overflow: hidden;
  max-height: 80px;

  border: 1px solid transparent;
  border-radius: 3px;

  :hover,
  :focus {
    border-color: rgb(167 182 194 / 60%);
  }
  :focus-within {
    outline: var(--color-blue-hex) solid 2px;
    outline-offset: -2px;
  }
`;

export const TextArea = styled.textarea`
  height: 100%;
  width: 100%;
  padding: 4px;

  resize: none;
  border: none;
  border-radius: inherit;

  text-align: center;
  line-height: 1.5;
  letter-spacing: 0.33px;

  background-color: transparent;
  outline: none;
  overflow: hidden auto;
  /* color: ${Colors.DARK_GRAY5}; */

  opacity: ${({ $faded }) => ($faded ? 0.65 : 1)};

  :focus {
    background-color: ${Colors.WHITE};
  }
`;

const warningStyle = css`
  background-color: var(--color-red-hsl-light);
`;

export const CharactersContainer = styled.div`
  border-radius: 3px;
  /* background-color: rgb(235 241 245 / 55%); */
  background-color: rgb(231 237 242 / 55%);
  /* background-color: #f6f9fa; */
  color: ${Colors.DARK_GRAY4};
  line-height: 1.5;
  padding: 7px 2px 4px;
  /* text-align: center; */
  font-size: 0.75rem;
  /* display: flex;
  flex-direction: column;
  gap: 5px; */

  height: 82px;

  > span {
    height: 100%;
    width: 100%;
  }

  ${({ $warning }) => $warning && warningStyle};
`;

export const CharactersWrapper = styled.span`
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100%;

  text-align: center;
`;

export const CharactersLine = styled.span`
  display: block;
`;
