// vendors
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// actions
import {
  addToast,
  fetchSrtData,
  // currentTime,
  // restoreAutosaveCount,
  saveSrtData,
  // seeking,
} from '../../States/Actions';

// components
import Layout from '../../Components/Layout';
import PageLoader from '../../Components/PageLoader';
import { withShortcutsProvider } from '../../Components/Shortcuts/Shortcuts.context';
import {
  useVideoPlayer,
  withVideoPlayerProvider,
} from '../../Components/VideoPlayer';

// sections
import VideoSection from '../../Sections/VideoSection';
import SubtitleSection from './SubtitleSection';

// styles
import { Container, StyledTimeline } from './SubtitleEditorView.styles';

const SubTitleEditorView = () => {
  const dispatch = useDispatch();

  const { seekToSeconds, isReady: isPlayerReady } = useVideoPlayer();

  const saveTimeoutRef = useRef(null);

  // const currentTimeVal = useSelector(
  //   (state) => state.srtData.present.currentTime
  // );

  // const subtitles = useSelector(
  //   (state) => state.srtData.present.data.subtitles
  // );

  const { progress } = useVideoPlayer();
  // const seekingTime = useSelector((state) => state.media.seekingTime);
  const loadingStatus = useSelector((state) => state.srtData.present.status);

  // const actionsLeftBeforeAutosave = useSelector(
  //   (state) => state.srtData.present.actionsLeftBeforeAutosave
  // );

  const editSinceLastSaved = useSelector(
    (state) => state.srtData.present.data.editSinceLastSaved
  );

  const handleShare = useCallback(() => {
    const url = window.location.href.split('&time=')[0];
    navigator.clipboard.writeText(`${url}&time=${progress.playedSeconds}`);

    dispatch(
      addToast({
        message: 'Link has been copied to clipboard',
        timeout: 3000,
        icon: 'info-sign',
      })
    );
  }, [progress.playedSeconds, dispatch]);

  const handleSave = useCallback(() => dispatch(saveSrtData), [dispatch]);

  // // Synchronize currentTime from global state to video player progress
  // useEffect(() => {
  //   seekToSeconds(currentTimeVal);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentTimeVal]);

  // fetch data  only once
  useEffect(() => {
    dispatch(fetchSrtData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // set video time only once when video player is ready
  useEffect(() => {
    if (!isPlayerReady) return;

    let time = 0;
    if (window.location.href.includes('&time=')) {
      const splittedUrl = window.location.href.split('&time=');
      time = Number(splittedUrl[1]);
    }

    // dispatch(seeking(time + 0.0001));
    seekToSeconds(time + 0.0001);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlayerReady]);

  // save content 30 secs after the last edit
  useEffect(() => {
    if (editSinceLastSaved) {
      saveTimeoutRef.current = setTimeout(() => handleSave(), 3 * 10000);
    }

    return () => {
      clearTimeout(saveTimeoutRef.current);
    };
  }, [dispatch, editSinceLastSaved, handleSave]);

  return (
    <Layout onSave={handleSave} onShare={handleShare}>
      <Container>
        {loadingStatus === 'loading' && <PageLoader />}

        <SubtitleSection />

        <VideoSection />

        <StyledTimeline />
      </Container>
    </Layout>
  );
};

export default withShortcutsProvider(
  withVideoPlayerProvider(SubTitleEditorView)
);
