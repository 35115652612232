function DefaultSegmentMarker(options) {
  options.draggable = true;
  this._options = options;
}

DefaultSegmentMarker.prototype.init = function (group) {
  var handleWidth = 15;
  var handleHeight = 35;
  // Place in the middle of the marker
  var handleX = this._options.startMarker ? 0 : -handleWidth;

  // Handle - create with default y, the real value is set in fitToView().

  this._handle = new window.Konva.Rect({
    x: handleX,
    y: 10,
    width: handleWidth,
    height: handleHeight,
    fill: this._options.color,
    stroke: this._options.color,
    strokeWidth: 1,
    // cornerRadius: 4,
    // visible: this._options.segment.attributes.visibleMarkers,
  });

  this._handle.on('dblclick', (algo) => {
    const isStartMarker = this._options.color === this._options.segmentOptions.startMarkerColor;
    const event = new CustomEvent('marker-dblclick', {
      detail: {
        segmentId: this._options.segment._id,
        isStartMarker: isStartMarker,
      }
    });
    document.dispatchEvent(event);
  });

  // Vertical Line - create with default y and points, the real values
  // are set in fitToView().
  this._line = new window.Konva.Line({
    x: 0,
    y: 0,
    stroke: this._options.color,
    strokeWidth: 1,
  });

  group.add(this._line);
  group.add(this._handle);

  this.fitToView();
  // this.bindEventHandlers(group);
};

DefaultSegmentMarker.prototype.fitToView = function () {
  var height = this._options.layer.getHeight();

  this._handle.y(height / 2 - 17);

  this._line.points([0.5, 0, 0.5, height]);
};

export function createSegmentMarker(options) {
  return new DefaultSegmentMarker(options);
}
