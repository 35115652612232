import arraysEqual from '../../../Utils/arraysEqual';

export const findDuplicatesFromProvidedKeyMap = (keyMap) =>
  Object.entries(keyMap).filter((item, index, array) => {
    const { modifiers, key } = item[1].combination;

    const self = array.map((a) => a[1]);

    const hasModifiers = modifiers && modifiers.length > 0;

    if (hasModifiers && key) {
      return (
        index !==
        self.findIndex(
          (s) =>
            s.combination.modifiers &&
            arraysEqual(modifiers, s.combination.modifiers) &&
            s.combination.key &&
            s.combination.key === key
        )
      );
    }

    if (hasModifiers && !key) {
      return (
        index !==
        self.findIndex(
          (s) =>
            s.combination.modifiers &&
            arraysEqual(modifiers, s.combination.modifiers) &&
            !s.combination.key
        )
      );
    }

    return (
      index !==
      self.findIndex(
        (s) =>
          (!s.combination.modifiers || s.combination.modifiers.length === 0) &&
          s.combination.key === key
      )
    );
  });

export const findDuplicatesFromSubscribedShortcuts = (keyMap, shortcuts) =>
  Object.entries(keyMap).filter((item) => {
    const { modifiers, key } = item[1].combination;

    const hasModifiers = modifiers && modifiers.length > 0;

    // Check for modifiers and key
    if (hasModifiers && key) {
      return Object.values(shortcuts)
        .filter((s) => s.combination.modifiers && s.combination.key)
        .some(
          (s) =>
            arraysEqual(s.combination.modifiers, modifiers) &&
            s.combination.key === key
        );
    }

    // Check for modifiers only
    if (hasModifiers && !key) {
      return Object.values(shortcuts)
        .filter((s) => s.combination.modifiers && !s.combination.key)
        .some((s) => arraysEqual(s.combination.modifiers, modifiers));
    }

    // Check for key only
    return Object.values(shortcuts)
      .filter(
        (s) =>
          (!s.combination.modifiers || s.combination.modifiers.length === 0) &&
          s.combination.key
      )
      .some((s) => s.combination.key === key);
  });

export const findDuplicates = (keyMap, shortcuts) => {
  // Discard validation on identical keys
  const sameKeyFound = Object.keys(keyMap).find((item) =>
    Object.keys(shortcuts).some((k) => k === item)
  );
  if (sameKeyFound) return;

  // Find duplicates inside provided keyMap
  let duplicatesFound = findDuplicatesFromProvidedKeyMap(keyMap);

  if (
    Object.keys(shortcuts).length > 0 &&
    (!duplicatesFound || duplicatesFound.length === 0)
  ) {
    // Find duplicates among existing subscribed shortcuts
    // const subscribedShortcuts = Object.entries(shortcuts).map((s) => s[1]);
    duplicatesFound = findDuplicatesFromSubscribedShortcuts(keyMap, shortcuts);
  }

  if (duplicatesFound.length > 0) {
    throw new Error(
      `Shortcut combination already taken. To avoid conflicts, please provide a unique combination for the following provided shortcut(s):\n${duplicatesFound
        .map((d) => d[0])
        .join('\n')}`
    );
  }
};
