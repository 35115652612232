import styled from 'styled-components';
import { Dialog as BpDialog, Colors } from '@blueprintjs/core';

export const Container = styled.div`
  overflow-y: auto;
  padding: 0 24px;
`;

export const Dialog = styled(BpDialog)`
  max-height: 85vh;

  .bp3-dialog-header {
    padding: 16px 14px 10px 24px;
    background: #1e996e;
    background-image: linear-gradient(90deg, #1e996e 0%, #53dc41 100%);
  }

  .bp3-dialog-header {
    > * {
      color: ${Colors.WHITE};
    }
    .bp3-icon {
      color: ${Colors.WHITE};
    }
  }
`;

export const Table = styled.table`
  font-weight: 400;
  font-size: 16px;
  font-size: 0.875rem;
  line-height: 1.25;
  color: #0b0c0c;
  width: 100%;
  /* margin-bottom: 20px; */
  border-spacing: 0;
  border-collapse: collapse;
  margin-top: 1.5rem;
`;

export const TableBody = styled.tbody`
  > * + * {
    border-top: 1px solid ${Colors.LIGHT_GRAY1};
  }
`;

export const TableRow = styled.tr``;

export const TableHead = styled.th`
  margin: 0;
  padding: 10px 20px 10px 0;

  font-weight: 600;
  text-align: left;
  vertical-align: top;
`;

export const TableCell = styled.td`
  padding: 10px 20px 10px 0;

  text-align: left;
  vertical-align: top;

  :last-child {
    padding-right: 0;
  }
`;
