// vendors
import React, { useEffect } from 'react';
import { useShortcuts } from '../Shortcuts.context';

const useKeys = (keyMap) => {
  const { subscribeKeys } = useShortcuts();
  const subscription = React.useRef(null);

  // only trigger once
  useEffect(() => {
    if (!subscribeKeys) return;

    subscription.current = subscribeKeys(keyMap);
    // subscribeKeys(keyMap);

    return () => {
      if (!subscription.current) return;

      subscription.current.unsubscribeKeys();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useKeys;
