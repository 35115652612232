/* eslint-disable import/no-anonymous-default-export */
/**
 * From video-react {@link https://github.com/video-react/video-react/blob/master/src/utils/index.js}
 *
 * Format seconds as a time string, H:MM:SS or M:SS
 * Supplying a guide (in seconds) will force a number of leading zeros
 * to cover the length of the guide
 *
 * @param  {Number} time  time in seconds to be turned into a string
 * @param  {Number} guide Number (in seconds) to model the string after
 * @return {String}       Time formatted as H:MM:SS or M:SS
 * @private
 * @function secondsToTimeShort
 */
export const secondsToTimeShort = (time, guide = time) => {
  let seconds = Math.floor(time % 60);
  let minutes = Math.floor((time / 60) % 60);
  let hours = Math.floor(time / 3600);

  const guideMinutes = Math.floor((guide / 60) % 60);
  const guideHours = Math.floor(guide / 3600);

  // handle invalid times
  if (isNaN(seconds) || seconds === Infinity) {
    // '-' is false for all relational operators (e.g. <, >=) so this setting
    // will add the minimum number of fields specified by the guide
    hours = '-';
    minutes = '-';
    seconds = '-';
  }

  // Check if we need to show hours
  hours = hours > 0 || guideHours > 0 ? `${hours}:` : '';

  // If hours are showing, we may need to add a leading zero.
  // Always show at least one digit of minutes.
  minutes = `${
    (hours || guideMinutes >= 10) && minutes < 10 ? `0${minutes}` : minutes
  }:`;

  // Check if leading zero is need for seconds
  seconds = seconds < 10 ? `0${seconds}` : seconds;

  return hours + minutes + seconds;
};

/**
 * From https://gist.github.com/vankasteelj/74ab7793133f4b257ea3
 *
 * Format seconds as a time string formatted as HH:MM:SS.MMS
 *
 * @param  {Number} timeInSeconds  time in seconds to be turned into a string
 * @return {String} Time formatted as HH:MM:SS.MMS
 */
export const secondsToTimeLong = (timeInSeconds) => {
  const pad = (num, size) => {
      return ('000' + num).slice(size * -1);
    },
    time = parseFloat(timeInSeconds).toFixed(3),
    hours = Math.floor(time / 60 / 60),
    minutes = Math.floor(time / 60) % 60,
    seconds = Math.floor(time - minutes * 60),
    milliseconds = time.slice(-3);

  return (
    pad(hours, 2) +
    ':' +
    pad(minutes, 2) +
    ':' +
    pad(seconds, 2) +
    '.' +
    pad(milliseconds, 3)
  );
};

export const timeLongToSeconds = (timeInString) => {
  if (!timeInString) return;

  const time = timeInString
    .split(/[:.]/)
    .map((value, index) => {
      if (index === 3) return value.padEnd(3, '0');
      return value.padEnd(2, '0');
    })
    .map((value) => parseFloat(value, 10));

  const hours = time[0] * 3600;
  const minutes = time[1] * 60;
  const seconds = time[2];
  const milliseconds = time[3] / 1000;

  return hours + minutes + seconds + milliseconds;
};
