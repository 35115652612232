import React from 'react';

const IconCaptions = ({ inverted, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="16"
      viewBox="0 0 24 16"
      {...props}
    >
      <g id="Icon-captions">
        <rect
          id="frame"
          width="24"
          height="16"
          rx="1"
          fill="#fff"
          opacity={inverted ? '1' : '0'}
        />
        <path
          id="frame-outline"
          d="M2,2V14H22V2H2M1,0H23a1,1,0,0,1,1,1V15a1,1,0,0,1-1,1H1a1,1,0,0,1-1-1V1A1,1,0,0,1,1,0Z"
          rx="1"
          fill="#fff"
          opacity={inverted ? '0' : '1'}
        />
        <rect
          id="line-1-square"
          width="3"
          height="2"
          rx="1"
          transform="translate(3 8)"
          fill={inverted ? '#000' : '#fff'}
        />
        <rect
          id="line-2-square"
          width="3"
          height="2"
          rx="1"
          transform="translate(18 11)"
          fill={inverted ? '#000' : '#fff'}
        />
        <rect
          id="line-1-rectangle"
          width="14"
          height="2"
          rx="1"
          transform="translate(7 8)"
          fill={inverted ? '#000' : '#fff'}
        />
        <rect
          id="line-2-rectangle"
          width="14"
          height="2"
          rx="1"
          transform="translate(3 11)"
          fill={inverted ? '#000' : '#fff'}
        />
      </g>
    </svg>
  );
};

export default IconCaptions;
