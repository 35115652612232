import React, { useState } from 'react';
import { Button, Icon } from '@blueprintjs/core';
import { Classes } from '@blueprintjs/popover2';
import 'styled-components/macro';

// styles
import {
  headingStyles,
  BottomWrapper,
  Highlighted,
  Checkbox,
  IndexIndicator,
  List,
} from './SubtitleWarnings.styles';

function SubtitleWarnings({ warnings, onClose }) {
  const constructSentenceToCorrect = (warning) => {
    if (warning && warning.context && warning.contextoffset) {
      const sentence = warning.context;
      const highlightStartIndex = warning.contextoffset;
      const highlightEndIndex = highlightStartIndex + warning.length;

      const firstPart = warning.context.substring(0, highlightStartIndex);
      const highlightedPart = warning.context.substring(
        highlightStartIndex,
        highlightEndIndex
      );
      const lastPart = warning.context.substring(
        highlightEndIndex,
        sentence.length
      );

      return (
        <>
          <span>{firstPart}</span>
          <Highlighted>{highlightedPart}</Highlighted>
          <span>{lastPart}</span>
        </>
      );
    }
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckedChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <>
      <h2 css={headingStyles}>
        <Icon icon="error" intent="danger" size={24} />
        <span>
          {`Correction${warnings.length > 1 ? 's' : ''} suggérée${
            warnings.length > 1 ? 's' : ''
          } ${warnings.length > 1 ? `(${warnings.length})` : ``}`}
        </span>
      </h2>

      <List>
        {warnings.map((warning, index) => (
          <li key={`warning-${index}`}>
            {warnings.length > 1 && (
              <IndexIndicator>{`#${index + 1}`}</IndexIndicator>
            )}
            <p>{warning.message}</p>
            <h3>Contexte</h3>
            <p>{constructSentenceToCorrect(warning)}</p>

            {warning.replacements.length > 0 && (
              <>
                <h3>Suggestions</h3>
                <ul
                  css={`
                    list-style-type: disc;
                  `}
                >
                  {warning.replacements.map((suggestion, index) => (
                    <li key={`suggestion-${index}`}>{suggestion}</li>
                  ))}
                </ul>
              </>
            )}
          </li>
        ))}
      </List>

      <BottomWrapper>
        <Checkbox
          label="Ne plus afficher"
          checked={isChecked}
          onChange={handleCheckedChange}
        />
        <Button
          text="Fermer"
          className={Classes.POPOVER2_DISMISS}
          onClick={() => onClose(isChecked)}
        />
      </BottomWrapper>
    </>
  );
}

export default SubtitleWarnings;
