// vendors
import React, { useCallback, useState, useRef, useEffect } from 'react';
import { Button } from '@blueprintjs/core';
import { Classes, Popover2 as Popover } from '@blueprintjs/popover2';
import 'styled-components/macro';

// components
import SubtitleWarnings from '../SubtitleWarnings';

import {
  TimeContainer,
  SubtitleNumber,
  InputContainer,
  StyledCard,
  StatusBar,
  ControlsContainer,
  StyledTimepicker,
  SubtitleWpm,
} from './SubtitleCard.styles';
import { useSelector } from 'react-redux';
import ShowErrorWithWpm from '../ShowErrorWithWpm';

function SubtitleCard({
  index,
  isActive,
  warnings = [],
  onFocus,
  startTime,
  endTime,
  onStartTimeChange,
  onStartTimeBlur,
  onEndTimeChange,
  onEndTimeBlur,
  onDelete,
  onCreateAfter,
  children,
  isCaptionsForTv,
  fps,
  wpm,
  lines,
  subtitles,
  subtitleColor,
  ...rest
}) {
  const [hideWarnings, setHideWarnings] = useState(false);
  const [redTime, setRedTime] = useState('');
  const min1Second = useSelector((state) => state.srtData.present.min1Second);
  const canSave = useSelector((state) => state.srtData.present.canSave);

  // const scrollTo = useRef();
  const refDeleteButton = useRef(null);

  const emitFocus = useCallback(() => {
    if (typeof onFocus === 'function') onFocus();
  }, [onFocus]);

  const emitStartTimeBlur = useCallback(
    (e) => {
      if (typeof onStartTimeBlur === 'function') onStartTimeBlur(e);
    },
    [onStartTimeBlur]
  );

  const emitEndTimeBlur = useCallback(
    (e) => {
      if (typeof onEndTimeBlur === 'function') onEndTimeBlur(e);
    },
    [onEndTimeBlur]
  );

  const emitDelete = useCallback(
    (e) => {
      if (typeof onDelete === 'function') onDelete();
    },
    [onDelete]
  );

  const emitCreateAfter = useCallback(() => {
    if (typeof onCreateAfter === 'function') onCreateAfter();
  }, [onCreateAfter]);

  const handleDelete = useCallback(() => {
    emitDelete();

    // force removing focus on the button to prevent
    // any undesired further click event via a keyboard key pressed
    refDeleteButton.current.blur();
    document.activeElement.blur();
  }, [emitDelete]);

  useEffect(() => {
    if (isCaptionsForTv || min1Second) {
      const isMinThan1sec = endTime?.value - startTime?.value < 1;
      const isMoreThan5Seconds = endTime?.value - startTime?.value > 5;
      if (isMinThan1sec) {
        setRedTime('#ff8080');
      } else if (isCaptionsForTv && isMoreThan5Seconds) {
        setRedTime('#ff8080');
      } else {
        setRedTime('');
      }
    }
  }, [startTime?.value, endTime?.value, isCaptionsForTv, min1Second, subtitleColor])

  return (
    <StyledCard
      $warning={warnings.length > 0 && !hideWarnings}
      $active={isActive}
      {...rest}
    >
      <SubtitleNumber $active={isActive}>{index + 1}</SubtitleNumber>
      {isCaptionsForTv ? (
        <SubtitleWpm
          $active={isActive}
          style={{ color: wpm > 325 ? 'red' : 'black' }}
        >
          {wpm} wpm
        </SubtitleWpm>
      ) : (
        <></>
      )}
      <TimeContainer style={{ backgroundColor: redTime }}>
        <StyledTimepicker
          index={index}
          time={startTime?.value}
          updated={startTime?.updated}
          // onChange={emitStartTimeChange}
          onBlur={emitStartTimeBlur}
          onFocus={emitFocus}
          leftIcon="double-chevron-right"
          isOnVideoSection={false}
        />

        <StyledTimepicker
          index={index}
          time={endTime?.value}
          updated={endTime?.updated}
          // onChange={emitEndTimeChange}
          onBlur={emitEndTimeBlur}
          onFocus={emitFocus}
          leftIcon="double-chevron-left"
          isOnVideoSection={false}
        />
      </TimeContainer>

      <InputContainer>{children}</InputContainer>

      <ControlsContainer>
        {canSave && (
        <>
        <Button
          icon="trash"
          elementRef={refDeleteButton}
          onClick={handleDelete}
          minimal
          small
        />

        <Button icon="plus" onClick={() => emitCreateAfter()} minimal small />
        </>
        )}
      </ControlsContainer>
      {warnings.length > 0 && !hideWarnings && (
        <StatusBar>
          <Popover
            interactionKind="click-target"
            popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
            hasBackdrop={false}
            enforceFocus={false}
            placement="right"
            content={
              <SubtitleWarnings
                warnings={warnings}
                onClose={(isHidden) => setHideWarnings(isHidden)}
              />
            }
            renderTarget={({ isOpen, ref, ...targetProps }) => (
              <Button
                {...targetProps}
                active={isOpen}
                elementRef={ref}
                icon="high-priority"
                minimal
                small
                css={`
                  & .bp3-icon {
                    color: hsl(var(--color-red-hs-val) 40%);
                  }
                `}
              />
            )}
          />
        </StatusBar>
      )}
      {isCaptionsForTv && (
        <ShowErrorWithWpm
          wpm={wpm}
          fps={fps}
          index={index}
          startTime={startTime}
          endTime={endTime}
          lines={lines}
          subtitles={subtitles}
        />
      )}
    </StyledCard>
  );
}

export default SubtitleCard;
