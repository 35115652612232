import React from 'react';
import { Slider } from '@blueprintjs/core';

const InverseSlider = (props) => {
    const { min, max, value, onChange } = props;
    const inverseValue = max - value;
  
    return (
      <Slider
        min={min}
        max={max}
        labelValues={[]}
        stepSize={1}
        value={inverseValue}
        onChange={(value) => onChange(max - value)}
      />
    );
  }

export default InverseSlider;
