// vendors
import React from 'react';

// views
import SubTitleEditorView from './Views/SubtitleEditorView';

// styles
import './Styles/App.css';

function App() {
  return <SubTitleEditorView />;
  /*return (
    <div class="container-message">
      <div style={{ textAlign: 'center' }}>
        <h1 style={{ marginBottom: '1rem' }}>
          Sunday August 11th, 21:00 to 23:59 New York Time, our services will be
          unavailable
        </h1>
        <img
          src="//soustitreur.com/images/logo/logo.png"
          alt="Logo"
          style={{
            height: '10rem',
          }}
        />
      </div>
    </div>
  );*/
}

export default App;
