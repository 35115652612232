export const ADD_TOAST = 'ADD_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';
export const REMOVE_ALL_TOAST = 'REMOVE_ALL_TOAST';

export const addToast =
  ({ message, timeout, intent, icon }) =>
  (dispatch, getState) => {
    const toasts = getState().toasts.toasts;
    const toastFound = toasts.find((t) => t.message === message);

    if (toastFound) {
      dispatch({ type: REMOVE_TOAST, payload: { id: toastFound.id } });
    }

    dispatch({ type: ADD_TOAST, payload: { message, timeout, intent, icon } });
  };

export const removeToast = (id) => (dispatch) => {
  dispatch({ type: REMOVE_TOAST, payload: { id } });
};

export const removeAllToast = (dispatch) => {
  dispatch({ type: REMOVE_ALL_TOAST });
};
