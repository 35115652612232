export const modifier = {
  ALT: 'Alt',
  CTRL: 'Ctrl',
  SHIFT: 'Shift',
  META: 'Meta',
};

export const modifierKeyMap = {
  Alt: 'altKey',
  Ctrl: 'ctrlKey',
  Shift: 'shiftKey',
  Meta: 'metaKey',
};

export const alphabetKeyCodeMap = {
  a: 'KeyA',
  b: 'KeyB',
  c: 'KeyC',
  d: 'KeyD',
  e: 'KeyE',
  f: 'KeyF',
  g: 'KeyG',
  h: 'KeyH',
  i: 'KeyI',
  j: 'KeyJ',
  k: 'KeyK',
  l: 'KeyL',
  m: 'KeyM',
  n: 'KeyN',
  o: 'KeyO',
  p: 'KeyP',
  q: 'KeyQ',
  r: 'KeyR',
  s: 'KeyS',
  t: 'KeyT',
  u: 'KeyU',
  v: 'KeyV',
  w: 'KeyW',
  x: 'KeyX',
  y: 'KeyY',
  z: 'KeyZ',
};

export const digitKeyCodeMap = {
  '+': 'Equal',
  '-': 'Minus',
};

export const keyCodeMap = {
  ...alphabetKeyCodeMap,
  ...digitKeyCodeMap,
};

export const UIKeyCommonMap = {
  ArrowLeft: '&larr;',
  ArrowRight: '&rarr;',
  ArrowDown: '&darr;',
  ArrowUp: '&uarr;',
};

export const UIKeyMapFr = {
  Space: 'Espace',
  Enter: 'Entrée',
};
