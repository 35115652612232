// vendors
import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Peaks from 'peaks.js';
import { Spinner, Colors } from '@blueprintjs/core';
import styled from 'styled-components';

// actions
import { modifyMultipleCaption, editSubtitle } from '../States/Actions';

// utils
import { createSegmentMarker } from '../Utils/CustomSegmentMarker';

// components
import { modifier, useKeys } from './Shortcuts';
import { useVideoPlayer } from './VideoPlayer';

const Container = styled.div`
  position: relative;
  width: 100%;

  .zoomview-container {
    border-top: 1px solid #91919147;
    margin: 5px 0 0;
    line-height: 0;
    height: calc(var(--sst-timeline-height) - 50px);
  }

  .overview-container {
    border-top: 1px solid #91919147;
    line-height: 0;
    height: calc(var(--sst-timeline-height) - 130px);
  }
`;

const SpinnerContainer = styled.div`
  position: absolute;
  inset: 0;
  z-index: 100;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  background: #ced9e0;
`;

const keyMap = {
  MOVE_TIMELINE_LEFT: {
    action: 'Move the timeline to the left',
    combination: {
      key: 'ArrowLeft',
    },
  },
  MOVE_TIMELINE_RIGHT: {
    action: 'Move the timeline to the right',
    combination: {
      key: 'ArrowRight',
    },
  },
  DISABLE_MAGNETISM: {
    action: 'Disable disable magnetism',
    combination: {
      modifiers: [modifier.SHIFT],
    },
    customCombinationLabel: 'Hold Shift',
  },
};

const BUFFER_MARKER_TIME = 0.2;

function Waveform() {
  const dispatch = useDispatch();

  const { isReady: isPlayerReady } = useVideoPlayer();

  const [peaksReady, setPeaksReady] = useState(false);

  const peaksUrl = useSelector((state) => state.srtData.present.peaksUrl);
  const verticalZoom = useSelector((state) => state.media.verticalZoom);
  const horizontalZoom = useSelector((state) => state.media.horizontalZoom);
  const samplesPerPixel = useSelector((state) => state.srtData.present.samplesPerPixel);
  const isCaptionsForTv = useSelector((state) => state.srtData.present.isCaptionsForTv);
  const min1Second = useSelector((state) => state.srtData.present.min1Second);
  const lastSubtitleColorChanged = useSelector((state) => state.srtData.present.data.lastSubtitleColorChanged);
  const timeDifRef = useRef(0);

  const subtitles = useSelector(
    (state) => state.srtData.present.data.subtitles
  );
  const startMagnetSpots = useSelector(
    (state) => state.srtData.present.startMagnetSpots
  );
  const endMagnetSpots = useSelector(
    (state) => state.srtData.present.endMagnetSpots
  );

  const peaks = useRef();
  const currentMagnetTime = useRef();
  const zoomviewWaveformRef = useRef();
  const overviewWaveformRef = useRef();
  const audioElementRef = useRef();
  const subtitlesRef = useRef();
  const shiftDown = useRef();

  useKeys(keyMap);

  useEffect(() => {
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Shift') shiftDown.current = true;
    });
    document.addEventListener('keyup', (event) => {
      if (event.key === 'Shift') shiftDown.current = false;
    });
  }, []);

  useEffect(
    function initPeaksJs() {
      if (peaksUrl && isPlayerReady) initPeaks();
    },
    [peaksUrl, isPlayerReady]
  );

  useEffect(
    function incomingVerticalZoomChange() {
      if (peaks.current) {
        const zoomView = peaks.current.views.getView('zoomview');
        zoomView.setAmplitudeScale(verticalZoom + 1);
      }
    },
    [verticalZoom]
  );

  useEffect(
    function incomingHorizontalZoomChange() {
      if (peaks.current) {
        peaks.current.zoom.setZoom(horizontalZoom);
        peaks.current.segments.removeAll();
        createSegmentsFromSubtitles();
      }
    },
    [horizontalZoom]
  );

  useEffect(
    function syncSubtitlesRefWithGlobalState() {
      subtitlesRef.current = subtitles;
    },
    [subtitles]
  );

  const formatLabelText = useCallback((index, lines) => {
    return `${index + 1}: ${lines?.join(' ')}`;
  }, []);

  const createSegmentsFromSubtitles = useCallback(() => {
    peaks.current.segments.removeAll();
    peaks.current.segments.add(
      subtitles.map((sub, index) => ({
        startTime: sub.startTime.value,
        endTime: sub.endTime.value,
        labelText: formatLabelText(index, sub.lines),
        id: index,
        editable: true,
        attributes: {
          visibleMarkers: false,
        },
        color: sub.selected ? '#90EE90' : (isCaptionsForTv || min1Second ? sub.subtitleColor : '#fff'),
      }))
    );
  }, [formatLabelText, subtitles]);

  useEffect(
    function syncSegmentsWithSubtitles() {
      if (!peaks.current) return;

      let allSegments = peaks.current.segments.getSegments();

      if (allSegments.length !== subtitles.length) {
        createSegmentsFromSubtitles();
      } else {
        subtitles.forEach((sub, i) => {
          let toUpgrade = null;
          const labelText = formatLabelText(i, sub.lines);

          if (sub.startTime?.value !== allSegments[i].startTime)
            toUpgrade = { startTime: sub.startTime?.value };
          else if (sub.endTime?.value !== allSegments[i].endTime)
            toUpgrade = { endTime: sub.endTime?.value };
          else if (labelText !== allSegments[i].labelText) {
            toUpgrade = { labelText };
          }

          if (toUpgrade) {
            allSegments[i].update(toUpgrade);
          }
        });
      }
    },
    [createSegmentsFromSubtitles, formatLabelText, subtitles]
  );

  const handleDoubleClickOnMarker = useCallback((event) => {
    const {segmentId, isStartMarker} = event.detail;
    const currentTime = peaks.current.player.getCurrentTime();
    const currentSegment = { ...subtitlesRef.current[segmentId] };

    const neighbourSegmentIndex = isStartMarker ? segmentId - 1 : segmentId + 1;

    let neighbourSegment = { ...subtitlesRef.current[neighbourSegmentIndex] };

    if (isStartMarker) {
      if (currentSegment.startTime.value < currentTime || (neighbourSegment && neighbourSegment.endTime.value > currentTime)) return;
      currentSegment.startTime.value = currentTime;
    } else {
      if (currentSegment.endTime.value > currentTime || (neighbourSegment && neighbourSegment.startTime.value < currentTime) ) return;
      currentSegment.endTime.value = currentTime;
    }

    let newColor = null;
    if ((min1Second || isCaptionsForTv) && peaks.current) {
      const segment = peaks.current.segments.getSegments()[segmentId];
      if (segment) {
        const isMinThan1sec = currentSegment.endTime.value - currentSegment.startTime.value < 1;
        const isMoreThan5Seconds = currentSegment.endTime.value - currentSegment.startTime.value > 5;
        if (isMinThan1sec) {
          segment.update({ color: '#ff8080' });
          newColor = '#ff8080';
        } else if (isCaptionsForTv && isMoreThan5Seconds) {
          segment.update({ color: '#ff8080' });
          newColor = '#ff8080';
        } else {
          segment.update({ color: '#fff' });
          newColor = '#fff';
        }
      }
    }

    dispatch(editSubtitle(currentSegment.id, {
      start: currentSegment.startTime.value,
      end: currentSegment.endTime.value,
      subtitleColor: newColor
        ? newColor
        : currentSegment.subtitleColor,
    }));
  }, [dispatch, min1Second, isCaptionsForTv]);

  useEffect(() => {
    document.addEventListener('marker-dblclick', handleDoubleClickOnMarker);

    return () => {
      document.removeEventListener('marker-dblclick', handleDoubleClickOnMarker);
    };
  }, [handleDoubleClickOnMarker]);

  const resetMagnet = useCallback(() => {
    peaks.current.points.removeAll();
    currentMagnetTime.current = null;
  }, []);

  const applyMagnetism = useCallback((segmentTime, markerTime, callback) => {
    if (
      (segmentTime < markerTime &&
        segmentTime > markerTime - BUFFER_MARKER_TIME) ||
      (segmentTime > markerTime &&
        segmentTime < markerTime + BUFFER_MARKER_TIME)
    ) {
      if (typeof callback === 'function') callback();

      const isValid =
        Math.abs(segmentTime - markerTime) <
        Math.abs(segmentTime - currentMagnetTime.current);

      if (isValid) {
        currentMagnetTime.current = markerTime;
      }
    }
  }, []);

  const initMagnet = useCallback(
    (segment, isStartMarker) => {
      let magnetSpots = endMagnetSpots || [];
      let segmentTime = segment.endTime || 0;
      let neighbourIndex = segment.id + 1;

      if (isStartMarker) {
        magnetSpots = startMagnetSpots;
        segmentTime = segment.startTime;
        neighbourIndex = segment.id - 1;
      }

      // Apply magnetism in the following cases:
      // 1- Current segment start time closed to previous neighbour end time
      // 2- Current segment end time closed to next neighbour start time
      const neighbour = segment._peaks.segments._segmentsById[neighbourIndex];
      if (neighbour) {
        // check with neighbour
        const neighbourTime = isStartMarker
          ? neighbour.endTime
          : neighbour.startTime;

        applyMagnetism(segmentTime, neighbourTime);
      }

      // 4- Current segment start time closed to start magnet spots
      // 5- Current segment end time closed to end magnet spots
      magnetSpots.forEach((magnetSpot) => {
        applyMagnetism(segmentTime, magnetSpot, () => {
          peaks.current.points.add({
            time: magnetSpot,
            labelText: '',
            color: '#6c757d',
          });
        });
      });

      // 3- Current segment start or end time closed to current time
      const currentTime = peaks.current.player.getCurrentTime();
      applyMagnetism(segmentTime, currentTime);
    },
    [endMagnetSpots, applyMagnetism, startMagnetSpots]
  );

  const dragAllSelectedElements = useCallback(
    (currentSegment, marker, startMarker) => {
      const currentSub = subtitlesRef.current[currentSegment.id];

      const otherSelectedSubs = [...subtitlesRef.current]
        .map((sub, index) => {
          return {
            subtitle: sub,
            index,
          };
        })
        .filter(
          (sub) => sub.subtitle.selected && sub.index !== currentSegment.id
        );

      timeDifRef.current = 0;

      if (!marker && !startMarker) {
        timeDifRef.current = currentSegment.startTime - currentSub.initializedStartTime;
      } else {
        timeDifRef.current = currentSegment.endTime - currentSub.initializedEndTime;
      }

      for (let i = 0; i < otherSelectedSubs.length; i++) {
        const sub = otherSelectedSubs[i];
        const segment = peaks.current.segments.getSegments()[sub.index];
        const newStartTime = sub.subtitle.initializedStartTime + timeDifRef.current;
        const newEndTime = sub.subtitle.initializedEndTime + timeDifRef.current;
        const neighbourBefore = segment._peaks.segments._segmentsById[segment.id - 1];
        const neighbourAfter = segment._peaks.segments._segmentsById[segment.id + 1];

        if (
          neighbourBefore &&
          newStartTime <= neighbourBefore.endTime &&
          !neighbourBefore.selected && timeDifRef.current !== 0
        ) {
          sub.subtitle.selected = false;
          sub.subtitle.startTime.value = neighbourBefore.endTime;
          sub.subtitle.endTime.value = newEndTime;
          segment.update({
            startTime: neighbourBefore.endTime,
            selected: false,
            endTime: newEndTime,
            color: '#fff',
          });
        } else if (
          neighbourAfter &&
          newEndTime >= neighbourAfter.startTime &&
          !neighbourAfter.selected && timeDifRef.current !== 0
        ) {
          sub.subtitle.selected = false;
          sub.subtitle.endTime.value = neighbourAfter.startTime;
          sub.subtitle.startTime.value = newStartTime;
          segment.update({
            endTime: neighbourAfter.startTime,
            selected: false,
            startTime: newStartTime,
            color: '#fff',
          });
        } else if (timeDifRef.current !== 0 && !startMarker && !marker) {
          segment.update({
            startTime: newStartTime,
            endTime: newEndTime,
          });
        }

        if (
          timeDifRef.current === 0
        ) {
          sub.subtitle.selected = false;
          segment.update({
            selected: false,
            color: '#fff',
          });
        }
      }

    },
    [subtitlesRef]
  );

  const handleSegmentsDragStart = useCallback((event) => {
    const { segment , evt} = event;

    if (subtitlesRef.current[segment.id].selected) {
      const selectedSubs = [...subtitlesRef.current].map((sub, index) => {
        return {
          subtitle: sub,
          index
        }
      }).filter((sub) => sub.subtitle.selected);

      const modifySelected = selectedSubs.map((sub) => {
        const segment = peaks.current.segments.getSegments()[sub.index];

        sub.subtitle.initializedStartTime = segment.startTime;
        sub.subtitle.initializedEndTime = segment.endTime;

        return sub;
      });

      if (modifySelected.length > 0) {
        dispatch(modifyMultipleCaption(modifySelected));
      }
    }
  }, [dispatch])

  const handleSegmentsDragged = useCallback(
    (event) => {
      const { segment, startMarker, marker } = event

      resetMagnet();

      if (subtitlesRef.current[segment.id].selected) {
        dragAllSelectedElements(segment, marker, startMarker);
      }

      // deactivate magnetism when:
      // - SHIFT key is down;
      // - dragging a segment.
      const { startTime, endTime } = subtitlesRef.current[segment.id];
      const startTimeChanged = startTime.value !== segment._startTime;
      const endTimeChanged = endTime.value !== segment._endTime;

      if (shiftDown.current || (startTimeChanged || endTimeChanged)) {
        return;
      }

      initMagnet(segment, startMarker);
    },
    [initMagnet, resetMagnet, dragAllSelectedElements]
  );

  const checkColorChange = useCallback(
    (segment) => {
      if (segment) {
        if ((isCaptionsForTv || min1Second)) {
          const { startTime, endTime } = segment;
          const isMinThan1sec = endTime - startTime < 1;
          const isMoreThan5Seconds = endTime - startTime > 5;
          let newColor = '#fff';
          if (isMinThan1sec) {
            segment.update({ color: '#ff8080' });
            newColor = '#ff8080';
          } else if (isCaptionsForTv && isMoreThan5Seconds) {
            segment.update({ color: '#ff8080' });
            newColor = '#ff8080';
          } else if(segment.selected) {
            segment.update({ color: '#90EE90' });
            newColor = '#90EE90';
          } else {
            segment.update({ color: '#fff' });
            newColor = '#fff';
          }
          return newColor;
        } else if (segment.selected) {
          segment.update({ color: '#90EE90' });

          return '#90EE90';
        } else if (!segment.selected) {
          segment.update({ color: '#fff' });

          return '#fff';
        }
      }
    },
    [isCaptionsForTv, min1Second]
  );

  useEffect(
    function updateSegmentFromSubtitleColorChanged() {
      if (lastSubtitleColorChanged && peaks.current) {
        const index = subtitles.findIndex(({ id }) => id === lastSubtitleColorChanged.id);
        const segment = peaks.current.segments.getSegments()[index];
        if (segment) {
          checkColorChange(segment);
        }
      }
    },
    [lastSubtitleColorChanged, checkColorChange, subtitles]
  );

  const handleSegmentClick = useCallback((event) => {
    const { segment, evt } = event;

    let modifiedSubtitles = [];

    if (evt.ctrlKey && shiftDown.current) {
      let newSubtitle = {
        ...subtitlesRef.current[segment.id],
        selected: true,
      }

      segment.selected = true;
      newSubtitle.subtitleColor = checkColorChange(segment);

      const currentSub = subtitlesRef.current[segment.id];
      if (currentSub.selected) {
        newSubtitle = {
          ...subtitlesRef.current[segment.id],
          selected: false,
        };
        segment.selected = false;
        newSubtitle.subtitleColor = checkColorChange(segment);
      }

      modifiedSubtitles.push({
        subtitle: newSubtitle,
        index: segment.id,
      });


      dispatch(modifyMultipleCaption(modifiedSubtitles));
    } else {
      modifiedSubtitles = subtitlesRef.current.map((sub, index) => {
        if (sub.selected) {
          sub.selected = false;
          const currentSegment = segment._peaks.segments._segmentsById[index];
          currentSegment.selected = false;
          sub.subtitleColor = checkColorChange(currentSegment);
          return {subtitle: sub, index};
        }
        return null;
      }).filter(sub => sub !== null)
      dispatch(modifyMultipleCaption(modifiedSubtitles));
    }
  }, [dispatch, checkColorChange]);

  const handleWaveformClick = useCallback(
    (event) => {
      const modifiedSubtitles = subtitlesRef.current
        .map((sub, index) => {
          const segment = peaks.current.segments.getSegments()[index];
          if (sub.selected) {
            sub.selected = false;
            segment.selected = false;
            sub.subtitleColor = checkColorChange(segment);
            return { subtitle: sub, index };
          }
          return null;
        })
        .filter((sub) => sub !== null);
      dispatch(modifyMultipleCaption(modifiedSubtitles));
    },
    [dispatch, checkColorChange],
  )

  const handleSegmentsDragEnd = useCallback(
    (event) => {
      const { segment, startMarker } = event;

      if (segment.selected) {
        const selectedCells = subtitlesRef.current.filter((sub) => sub.selected);
        for (let i = 0; i < selectedCells.length; i++) {
          let cell = selectedCells[i];
          if (timeDifRef.current < 0) {
            let newEndTime = cell.endTime.value - Math.abs(timeDifRef.current);
            let newStartTime =
              cell.startTime.value - Math.abs(timeDifRef.current);
            cell.endTime.value = newEndTime;
            cell.startTime.value = newStartTime;
          } else {
            let newEndTime = cell.endTime.value + timeDifRef.current;
            let newStartTime = cell.startTime.value + timeDifRef.current;
            cell.endTime.value = newEndTime;
            cell.startTime.value = newStartTime;
          }
        }

      }

      let neighbourBefore = segment._peaks.segments._segmentsById[segment.id - 1];

      let neighbourAfter = segment._peaks.segments._segmentsById[segment.id + 1];

      let modifiedSubtitles = [];

      // Update previous end time or next start time
      if (neighbourBefore) {
        const neighbourBeforeIndex = segment.id - 1;
        if (
          neighbourBefore.endTime <
          subtitlesRef.current[neighbourBeforeIndex].endTime.value
        ) {
          let newSubtitle = { ...subtitlesRef.current[neighbourBeforeIndex] };

          newSubtitle.endTime = {
            ...newSubtitle.endTime,
            value: neighbourBefore.endTime,
          };

          newSubtitle.subtitleColor = checkColorChange(segment._peaks.segments._segmentsById[segment.id - 1]);

          modifiedSubtitles.push({
            subtitle: newSubtitle,
            index: neighbourBeforeIndex,
          });
        }
        if (
          neighbourBefore.startTime >
          subtitlesRef.current[neighbourBeforeIndex].startTime.value
        ) {
          let newSubtitle = { ...subtitlesRef.current[neighbourBeforeIndex] };

          newSubtitle.startTime = {
            ...newSubtitle.startTime,
            value: neighbourBefore.startTime,
          };

          newSubtitle.subtitleColor = checkColorChange(segment._peaks.segments._segmentsById[segment.id - 1]);

          modifiedSubtitles.push({
            subtitle: newSubtitle,
            index: neighbourBeforeIndex,
          });
        }
      }

      if (neighbourAfter) {
        const neighbourAfterIndex = segment.id + 1;
        if (
          neighbourAfter.endTime <
          subtitlesRef.current[neighbourAfterIndex].endTime.value
        ) {
          let newSubtitle = { ...subtitlesRef.current[neighbourAfterIndex] };

          newSubtitle.endTime = {
            ...newSubtitle.endTime,
            value: neighbourAfter.endTime,
          };

          newSubtitle.subtitleColor = checkColorChange(segment._peaks.segments._segmentsById[segment.id - 1]);

          modifiedSubtitles.push({
            subtitle: newSubtitle,
            index: neighbourAfterIndex,
          });
        }
        if (
          neighbourAfter.startTime >
          subtitlesRef.current[neighbourAfterIndex].startTime.value
        ) {
          let newSubtitle = { ...subtitlesRef.current[neighbourAfterIndex] };

          newSubtitle.startTime = {
            ...newSubtitle.startTime,
            value: neighbourAfter.startTime,
          };

          newSubtitle.subtitleColor = checkColorChange(segment._peaks.segments._segmentsById[segment.id + 1]);

          modifiedSubtitles.push({
            subtitle: newSubtitle,
            index: neighbourAfterIndex,
          });
        }
      }

      // update current start and end time
      let newSubtitle = { ...subtitlesRef.current[segment.id] };

      const startTimeMoving =
        segment._startTime !== newSubtitle.startTime.value;

      const endTimeMoving = segment._endTime !== newSubtitle.endTime.value;

      newSubtitle.startTime = {
        ...newSubtitle.startTime,
        value:
          !startTimeMoving || segment._startTime <= segment._endTime - 0.05
            ? segment._startTime
            : segment._startTime - 0.05,
      };
      newSubtitle.endTime = {
        ...newSubtitle.endTime,
        value:
          !endTimeMoving || segment._endTime >= segment._startTime + 0.05
            ? segment._endTime
            : segment._endTime + 0.05,
      };

      // modifiedSubtitles.push({ newCaption: newSubtitle, index: segment.id });

      // update current start and end time depending on magnet position
      if (currentMagnetTime.current) {
        if (neighbourBefore && startMarker) {
          const neighbourBeforeIndex = segment.id - 1;

          // set to current magnet time ONLY when current magnet time
          // is under or equal new current subtitle end time
          newSubtitle.startTime = {
            ...newSubtitle.startTime,
            value:
              currentMagnetTime.current <= newSubtitle.endTime.value
                ? currentMagnetTime.current
                : newSubtitle.startTime.value,
          };

          if (neighbourBefore && neighbourBefore.endTime > currentMagnetTime.current) {
            const neighbourBeforeSubtitle = {
              ...subtitlesRef.current[neighbourBeforeIndex],
            };

            // set to current magnet time ONLY when current magnet time
            // is above or equal neighbour subtitle start time
            neighbourBeforeSubtitle.endTime = {
              ...neighbourBeforeSubtitle.endTime,
              value:
                currentMagnetTime.current >= neighbourBeforeSubtitle.startTime.value
                  ? currentMagnetTime.current
                  : neighbourBeforeSubtitle.endTime.value,
            };

            neighbourBeforeSubtitle.subtitleColor = checkColorChange(segment._peaks.segments._segmentsById[segment.id - 1]);

            modifiedSubtitles.push({
              subtitle: neighbourBeforeSubtitle,
              index: neighbourBeforeIndex,
            });
          }

        }
        if (neighbourAfter && !startMarker) {
          const neighbourAfterIndex = segment.id + 1;
          // set to current magnet time ONLY when current magnet time
          // is above or equal new current subtitle start time
          newSubtitle.endTime = {
            ...newSubtitle.endTime,
            value:
              currentMagnetTime.current >= newSubtitle.startTime.value
                ? currentMagnetTime.current
                : newSubtitle.endTime.value,
          };

          if (neighbourAfter && neighbourAfter.startTime < currentMagnetTime.current) {
            const neighbourAfterSubtitle = {
              ...subtitlesRef.current[neighbourAfterIndex],
            };

            // set to current magnet time ONLY when current magnet time
            // is under or equal neighbour subtitle end time
            neighbourAfterSubtitle.startTime = {
              ...neighbourAfterSubtitle.startTime,
              value:
                currentMagnetTime.current <= neighbourAfterSubtitle.endTime.value
                  ? currentMagnetTime.current
                  : neighbourAfterSubtitle.startTime.value,
            };

            neighbourAfterSubtitle.subtitleColor = checkColorChange(segment._peaks.segments._segmentsById[segment.id - 1]);

            modifiedSubtitles.push({
              subtitle: neighbourAfterSubtitle,
              index: neighbourAfterIndex,
            });
          }
        }
      }

      newSubtitle.subtitleColor = checkColorChange(segment._peaks.segments._segmentsById[segment.id]);

      modifiedSubtitles.push({
        subtitle: newSubtitle,
        index: segment.id,
      });

      resetMagnet();

      dispatch(modifyMultipleCaption(modifiedSubtitles));
    },
    [dispatch, resetMagnet, checkColorChange]
  );


  const updateSegments = () => {
    if (!peaks.current || subtitlesRef.current.length === 0) {
      return;
    }

    const currentSegments = peaks.current.segments.getSegments();
    let hasChanges = false;

    subtitlesRef.current.forEach((subtitle, index) => {
      const segment = currentSegments[index];
      const formattedLabelText = formatLabelText(index, subtitle.lines);

      if (!segment) {
        hasChanges = true;
      } else if (
        segment.startTime !== subtitle.startTime.value ||
        segment.endTime !== subtitle.endTime.value ||
        segment.labelText !== formattedLabelText
      ) {
        hasChanges = true;
        segment.update({
          startTime: subtitle.startTime.value,
          endTime: subtitle.endTime.value,
          labelText: formattedLabelText,
        });
      }
    });

  };


  const handleUndo = (event) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
      updateSegments();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleUndo);

    return () => {
      document.removeEventListener('keydown', handleUndo);
    };
  }, []);


  const getAvailableZoomLevels = (samplesPerPixel) => {
    let zoomLevels = [Number(samplesPerPixel)];
    for (let i = 0; i < 2; i++) {
      zoomLevels.push(zoomLevels[i] * 2);
    }
    const lastZoomLevel = zoomLevels[zoomLevels.length - 1];
    lastZoomLevel < 1024 ? zoomLevels.push(1024, 2048) : zoomLevels.push(lastZoomLevel * 2, lastZoomLevel * 4);
    return zoomLevels;
  };

  const initPeaks = useCallback(() => {
    const player = document.querySelector('#react-player');
    audioElementRef.current = player.querySelectorAll('video, audio');

    const options = {
      zoomview: {
        container: zoomviewWaveformRef.current,
        waveformColor: Colors.GRAY1,
        playheadTextColor: '#182026',
        playheadColor: '#094393'
      },
      overview: {
        container: overviewWaveformRef.current,
        highlightColor: Colors.DARK_GRAY1,
      },
      mediaElement: audioElementRef.current[0],
      dataUri: {
        arraybuffer: peaksUrl,
      },
      zoomLevels: samplesPerPixel ? getAvailableZoomLevels(samplesPerPixel) : [256, 512, 1024, 2048, 4096],
      keyboard: true,
      logger: console.error.bind(console),
      randomizeSegmentColor: false,
      axisLabelColor: Colors.DARK_GRAY5,
      emitCueEvents: true,

      segmentOptions: {
        markers: true,
        overlay: true,
        waveformColor: '#f8f8f8',
        startMarkerColor: '#00E180',
        endMarkerColor: '#FF1C36',
        overlayColor: '#fff',
        overlayBorderColor: '#fff',
        overlayOpacity: 0.68,
        overlayBorderWidth: 0,
        overlayCornerRadius: 8,
        overlayOffset: 25,
        overlayLabelAlign: 'left',
        overlayLabelVerticalAlign: 'top',
        overlayLabelPadding: 20,
        overlayLabelColor: '#182026',
        overlayFontFamily: 'sans-serif',
        overlayFontSize: 13,
        overlayFontStyle: 'normal',
      },
      createSegmentLabel: () => {
        return null;
      },
    };

    Peaks.init(options, (error, initializedPeaks) => {
      if (error) console.error('error', error);
      peaks.current = initializedPeaks;

      initializedPeaks.options.createSegmentMarker = createSegmentMarker;

      // initializedPeaks.on('segments.enter', handlePlayHeadSegmentsEnter);
      // initializedPeaks.on('segments.click', handlePlayHeadSegmentsClick);
      initializedPeaks.on('segments.dragged', handleSegmentsDragged);
      initializedPeaks.on('segments.dragend', handleSegmentsDragEnd);
      initializedPeaks.on('segments.dragstart', handleSegmentsDragStart);
      initializedPeaks.on('segments.click', handleSegmentClick);
      initializedPeaks.on('zoomview.click', handleWaveformClick);

      const view = initializedPeaks.views.getView('zoomview');
      view._playheadLayer._playheadLine.attrs.strokeWidth = 4;
      view.setWheelMode('scroll', { captureVerticalScroll: true });
      view.enableSegmentDragging(true);
      view.setSegmentDragMode('compress');
      view.setMinSegmentDragWidth(2);
      // view.showPlayheadTime(true);
      // view.setTimeLabelPrecision(4);

      createSegmentsFromSubtitles();

      setPeaksReady(true);
      peaks.current.zoom.setZoom(horizontalZoom);
    });
  }, [
    createSegmentsFromSubtitles,
    handleSegmentsDragEnd,
    handleSegmentsDragged,
    peaksUrl,
  ]);

  return (
    <Container>
      {!peaksReady && (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )}

      <div className="zoomview-container" ref={zoomviewWaveformRef}></div>
      <div className="overview-container" ref={overviewWaveformRef}></div>
    </Container>
  );
}

export default Waveform;
