// vendors
import React, { useCallback, useState, useEffect, useMemo } from 'react';
// import { ActionCreators } from 'redux-undo';
import { UNDO, REDO, CLEAR } from 'easy-redux-undo';
import { useSelector, useDispatch } from 'react-redux';
import {
  Label as BpLabel,
  Slider as BpSlider,
  Button,
} from '@blueprintjs/core';
import styled from 'styled-components';
import 'styled-components/macro';

import InverseSlider from '../Components/InverseSlider';
import TimePicker from '../Components/TimePicker';

// actions
import {
  setVerticalZoom,
  setHorizontalZoom,
  verticalZoomIn,
  verticalZoomOut,
  horizontalZoomIn,
  horizontalZoomOut,
  currentTime,
  addToast,
} from '../States/Actions';

// reducers
// import { playbackRateOptions } from '../States/Reducers/media';

// components
import VideoPlayer, { useVideoPlayer } from '../Components/VideoPlayer';
import Shortcuts, { modifier, useKeys } from '../Components/Shortcuts';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  min-width: 500px;
  padding-top: 0.5em;
  padding-right: 0.5em;

  /* overflow-y: auto; */
`;

const Toolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 1.25em;
  column-gap: 1em;

  width: 100%;
  margin-top: 1em;
  padding-left: 4px;

  > :first-child {
    display: flex;
    flex-wrap: wrap;
    row-gap: 1.25em;
    column-gap: 1em;
  }

  input {
    margin-top: 6px;
  }

  input.search-box {
    max-width: 180px;
  }
`;

const Notificationbar = styled.div`
  align-items: center;
  column-gap: 1em;
  display: flex;
  flex-wrap: wrap;
  margin-top: 1em;
  padding-left: 4px;
  row-gap: 1.25em;
  width: 100%;
`;

const Label = styled(BpLabel)`
  && {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }

  &.undo-redo button {
    margin-top: 5px;
  }

  &.zoom {
    margin-right: 10px;
  }
`;

const Slider = styled(BpSlider)`
  :last-child {
    margin-left: 10px;
  }
`;

const keyMap = {
  UNDO: {
    action: 'Undo',
    combination: {
      modifiers: [modifier.CTRL],
      key: 'z',
    },
  },
  REDO: {
    action: 'Redo',
    combination: {
      modifiers: [modifier.CTRL, modifier.SHIFT],
      key: 'z',
    },
  },
  VERTICAL_ZOOM_IN: {
    action: 'Vertical zoom in',
    combination: {
      modifiers: [modifier.CTRL],
      key: '+',
    },
    customCombinationLabel: 'Ctrl (+) +',
  },
  VERTICAL_ZOOM_OUT: {
    action: 'Vertical zoom out',
    combination: {
      modifiers: [modifier.CTRL],
      key: '-',
    },
    customCombinationLabel: 'Ctrl (+) -',
  },
  HORIZONTAL_ZOOM_IN: {
    action: 'Horizontal zoom in',
    combination: {
      modifiers: [modifier.CTRL, modifier.SHIFT],
      key: '+',
    },
    customCombinationLabel: 'Ctrl + Shift (+) +',
  },
  HORIZONTAL_ZOOM_OUT: {
    action: 'Horizontal zoom out',
    combination: {
      modifiers: [modifier.CTRL, modifier.SHIFT],
      key: '-',
    },
    customCombinationLabel: 'Ctrl + Shift (+) -',
  },
};

function VideoSection() {
  const dispatch = useDispatch();

  const [search, setSearch] = useState('');
  const [lastSearchedIndex, setLastSearchedIndex] = useState(-1);

  const subtitles = useSelector(
    (state) => state.srtData.present.data.subtitles
  );

  const  {maxCharsPerLine, maxLines}  = useSelector(
    (state) => state.srtData.present.meta
  );

  // const playbackRate = useSelector((state) => state.media.playbackRate);
  const verticalZoom = useSelector((state) => state.media.verticalZoom);
  const horizontalZoom = useSelector((state) => state.media.horizontalZoom);
  const loadingStatus = useSelector((state) => state.srtData.present.status);
  const currentTimeVal = useSelector(
    (state) => state.srtData.present.currentTime
  );

  const videoUrl = useSelector((state) => state.srtData.present.videoUrl);
  const videoExists = useSelector((state) => state.srtData.present.videoExists);
  const audioSrc = useSelector((state) => state.srtData.present.audioLink);

  const finalUrl = videoExists ? videoUrl : audioSrc;

  const { progress, seekToSeconds } = useVideoPlayer();

  useEffect(() => {
    dispatch(CLEAR());
    // dispatch(ActionCreators.clearHistory());
  }, [dispatch]);

  const startSearch = useCallback(() => {
    let foundIndex;
    let time;
    subtitles.forEach((sub, index) => {
      if (
        !foundIndex &&
        index > lastSearchedIndex &&
        sub.lines.join('\n').toLowerCase().search(search.toLowerCase()) !== -1
      ) {
        foundIndex = index;
        time = sub.startTime.value;
        setLastSearchedIndex(index);
      }
    });
    if (!foundIndex) {
      setLastSearchedIndex(-1);
      dispatch(
        addToast({
          message: `"${search}" wasn't found in subtitles`,
          timeout: 3000,
          icon: 'warning-sign',
        })
      );
      return
    }
    seekToSeconds(time + 0.0001);
  }, [lastSearchedIndex, search, seekToSeconds, subtitles]);

  const handleUndo = useCallback(
    (e) => {
      e.preventDefault();

      // dispatch(ActionCreators.undo());
      dispatch(UNDO());
    },
    [dispatch]
  );

  const handleRedo = useCallback(
    (e) => {
      e.preventDefault();

      // dispatch(ActionCreators.redo());
      dispatch(REDO());
    },
    [dispatch]
  );

  // const handlePlaybackRateChange = useCallback(
  //   (e) => {
  //     const value = Number(e.target.value);
  //     dispatch(setPlaybackRate(value));
  //   },
  //   [dispatch]
  // );

  const handleVerticalZoomChange = useCallback(
    (value) => {
      dispatch(setVerticalZoom(value));
    },
    [dispatch]
  );

  const handleVerticalZoomIn = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(verticalZoomIn());
    },
    [dispatch]
  );

  const handleVerticalZoomOut = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(verticalZoomOut());
    },
    [dispatch]
  );

  const handleHorizontalZoomChange = useCallback(
    (value) => {
      dispatch(setHorizontalZoom(value));
    },
    [dispatch]
  );

  const handleHorizontalZoomIn = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(horizontalZoomIn());
    },
    [dispatch]
  );

  const handleHorizontalZoomOut = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(horizontalZoomOut());
    },
    [dispatch]
  );

  useMemo(() => {
    keyMap['UNDO'].handler = handleUndo;
    keyMap['REDO'].handler = handleRedo;
    keyMap['VERTICAL_ZOOM_IN'].handler = handleVerticalZoomIn;
    keyMap['VERTICAL_ZOOM_OUT'].handler = handleVerticalZoomOut;
    keyMap['HORIZONTAL_ZOOM_IN'].handler = handleHorizontalZoomIn;
    keyMap['HORIZONTAL_ZOOM_OUT'].handler = handleHorizontalZoomOut;
  }, [
    handleHorizontalZoomIn,
    handleHorizontalZoomOut,
    handleRedo,
    handleUndo,
    handleVerticalZoomIn,
    handleVerticalZoomOut,
  ]);

  useKeys(keyMap);

  // synchronize current time from the global state with the one from the video player state
  useEffect(() => {
    if (currentTimeVal === 0) return;

    if (currentTimeVal === progress.playedSeconds) return;

    seekToSeconds(currentTimeVal);
  }, [currentTimeVal]);

  // synchronize video player state with the one from the global state
  useEffect(() => {
    if (progress.playedSeconds === 0) return;

    if (currentTimeVal === progress.playedSeconds) return;

    dispatch(currentTime(progress.playedSeconds));
  }, [progress.playedSeconds]);

  return (
    <Container>
      <VideoPlayer
        src={finalUrl}
        captions={subtitles}
        css={`
          /* flex: 0; */

          width: 100%;
          /* height: 100%; */
        `}
      />
      {!videoExists && (
        <strong>
          Your video does not exists in our systems, but we still have the audio
        </strong>
      )}

      {loadingStatus !== 'loading' && (
        <>
          <Toolbar>
            <div>
              <Label>
                Current Time
                <TimePicker time={currentTimeVal} isOnVideoSection={true} />
              </Label>
              <Label className="undo-redo">
                Undo
                <Button icon="undo" onClick={handleUndo} />
              </Label>
              <Label className="undo-redo">
                Redo
                <Button icon="redo" onClick={handleRedo} />
              </Label>

              <Label>
                Search
                <input
                  className="search-box"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    setLastSearchedIndex(-1);
                  }}
                  onKeyUp={(e) => (e.key === 'Enter' ? startSearch() : null)}
                />
              </Label>

              <Label className="zoom">
                Zoom Vertical
                <Slider
                  min={0}
                  max={4}
                  stepSize={1}
                  labelValues={[]}
                  onChange={handleVerticalZoomChange}
                  value={verticalZoom}
                />
              </Label>
              <Label>
                Zoom Horizontal
                <InverseSlider
                  min={0}
                  max={4}
                  onChange={handleHorizontalZoomChange}
                  value={horizontalZoom}
                />
              </Label>
            </div>

            <div>
              <Shortcuts />
            </div>
          </Toolbar>
          <Notificationbar>
            <p>
              Max character count: <strong>{maxCharsPerLine}</strong>
            </p>
            <p>
              Max lines count: <strong>{maxLines}</strong>
            </p>
          </Notificationbar>
        </>
      )}
    </Container>
  );
}

export default VideoSection;
