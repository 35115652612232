import {
  // SEEKING,
  VERTICAL_ZOOM,
  VERTICAL_ZOOM_IN,
  VERTICAL_ZOOM_OUT,
  HORIZONTAL_ZOOM,
  HORIZONTAL_ZOOM_IN,
  HORIZONTAL_ZOOM_OUT,
  // IS_PLAYING,
  // PLAYBACK_RATE,
  // INCREASE_PLAYBACK_RATE,
  // DECREASE_PLAYBACK_RATE,
} from '../Actions';

// export const playbackRateOptions = [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];

export const ZOOM_MAX = 4;

const initialState = {
  // seekingTime: 0,
  horizontalZoom: 2, //Medium Zoom by default
  verticalZoom: 0,
  // currentTime: 0,
  // playbackRate: 1,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // case SEEKING:
    //   return {
    //     ...state,
    //     seekingTime: action.seekingTime,
    //   };
    case VERTICAL_ZOOM:
      return {
        ...state,
        verticalZoom: action.newZoom,
      };
    case VERTICAL_ZOOM_IN: {
      const current = state.verticalZoom;

      const verticalZoom = current === ZOOM_MAX ? current : current + 1;

      return {
        ...state,
        verticalZoom,
      };
    }
    case VERTICAL_ZOOM_OUT: {
      const current = state.verticalZoom;

      const verticalZoom = current === 0 ? current : current - 1;

      return {
        ...state,
        verticalZoom,
      };
    }
    //Horizontal Zoom works the opposite way
    case HORIZONTAL_ZOOM:
      return {
        ...state,
        horizontalZoom: action.newZoom,
      };
    case HORIZONTAL_ZOOM_IN: {
      const current = state.horizontalZoom;

      const horizontalZoom = current === 0 ? current : current - 1;

      return {
        ...state,
        horizontalZoom,
      };
    }
    case HORIZONTAL_ZOOM_OUT: {
      const current = state.horizontalZoom;

      const horizontalZoom = current === ZOOM_MAX ? current : current + 1;

      return {
        ...state,
        horizontalZoom,
      };
    }
    // case IS_PLAYING:
    //   return {
    //     ...state,
    //     isPlaying: action.isPlaying,
    //   };
    // case PLAYBACK_RATE:
    //   return {
    //     ...state,
    //     playbackRate: action.playbackRate,
    //   };
    // case INCREASE_PLAYBACK_RATE: {
    //   const current = state.playbackRate;

    //   const playbackRateIndex = playbackRateOptions.findIndex(
    //     (value) => value === current
    //   );

    //   const playbackRate =
    //     playbackRateIndex === playbackRateOptions.length - 1
    //       ? current
    //       : playbackRateOptions[playbackRateIndex + 1];

    //   return {
    //     ...state,
    //     playbackRate,
    //   };
    // }
    // case DECREASE_PLAYBACK_RATE: {
    //   const current = state.playbackRate;

    //   const playbackRateIndex = playbackRateOptions.findIndex(
    //     (value) => value === current
    //   );

    //   const playbackRate =
    //     playbackRateIndex === 0
    //       ? current
    //       : playbackRateOptions[playbackRateIndex - 1];

    //   return {
    //     ...state,
    //     playbackRate,
    //   };
    // }
    default:
      return state;
  }
};

export default reducer;
