// vendors
import React, { useCallback, useEffect, useState } from 'react';
import { CLEAR } from 'easy-redux-undo';
import { useDispatch } from 'react-redux';

// components
import {
  secondsToTimeLong,
  timeLongToSeconds,
} from '../VideoPlayer/utils/formatTime';
import { useVideoPlayer } from '../VideoPlayer';

// styles
import { Container, StyledIcon, StyledInput } from './TimePicker.styles';

// actions
import { currentTime } from '../../States/Actions';

const STEP_TIMESTAMP = 0.1;
const DEFAULT_TIMESTAMP = '--:--:--.---';
const INCREASE = 'INCREASE';
const DECREASE = 'DECREASE';
const DIGITS_SEPARATORS = '0123456789:.-';

const TimePicker = ({
  leftIcon,
  time,
  updated,
  onChange,
  onFocus,
  onBlur,
  index,
  isOnVideoSection,
  ...rest
}) => {
  const dispatch = useDispatch();
  const [timeValue, setTimeValue] = useState(DEFAULT_TIMESTAMP);
  const previousRef = React.useRef();

  const { getDuration } = useVideoPlayer();

  const emitFocus = useCallback(() => {
    if (typeof onFocus === 'function') onFocus();
  }, [onFocus]);

  const emitBlur = useCallback(
    (event) => {
      const value = event || 0;
      if (typeof onBlur === 'function') onBlur(value);
    },
    [onBlur]
  );

  const handleChange = useCallback((event) => {
    setTimeValue(event.target.value);
  }, []);

  const handleBlur = useCallback(
    (event) => {
      const timeInSeconds = timeLongToSeconds(event.target.value);

      if (!timeInSeconds) setTimeValue(previousRef.current);

      emitBlur(timeInSeconds);
    },
    [emitBlur]
  );

  const handleFocus = useCallback(() => {
    // const timeInSeconds = timeLongToSeconds(event.target.value);

    // emitBlur(timeInSeconds);

    emitFocus();
  }, [emitFocus]);

  const updateTimeStampByStep = useCallback(
    (timeStr, step, action) => {
      let timeInSeconds = timeLongToSeconds(timeStr);

      if (action === INCREASE) {
        timeInSeconds += step;
      } else {
        timeInSeconds -= step;
      }

      const duration = getDuration();

      if (duration && timeInSeconds > duration) {
        timeInSeconds = 0;
      }

      if (duration && timeInSeconds < 0) {
        timeInSeconds = duration;
      }

      const timeLongStr = secondsToTimeLong(timeInSeconds);

      setTimeValue(timeLongStr);
    },
    [getDuration]
  );

  useEffect(() => {
    dispatch(CLEAR());
  }, [dispatch]);
  
  const handleKeyDown = useCallback(
    (event) => {
      if (event.code === 'ArrowUp') {
        event.preventDefault();

        updateTimeStampByStep(timeValue, STEP_TIMESTAMP, INCREASE);
      } else if (event.code === 'ArrowDown') {
        event.preventDefault();

        updateTimeStampByStep(timeValue, STEP_TIMESTAMP, DECREASE);
      } else if (isOnVideoSection && (event.code === 'Enter' || event.code === 'NumpadEnter')) {
        const time = isNaN(timeValue) 
          ? timeValue
          : timeValue <= 0 
            ? secondsToTimeLong(0.1)
            : secondsToTimeLong(timeValue);
        if(!isNaN(timeValue)) setTimeValue(time);
        dispatch(currentTime(timeLongToSeconds(time)));
      }
    },
    [timeValue, updateTimeStampByStep, isOnVideoSection, dispatch]
  );

  const handleKeyPress = useCallback((event) => {
    if (DIGITS_SEPARATORS.includes(event.key) === false) {
      event.stopPropagation();
      event.preventDefault();
    }
  }, []);

  useEffect(() => {
    const timeStr = secondsToTimeLong(time);

    previousRef.current = timeStr;
  }, []);

  useEffect(() => {
    const timeStr = secondsToTimeLong(time);

    setTimeValue(timeStr);

    previousRef.current = timeStr;
  }, [time, updated]);

  return (
    <Container {...rest}>
      {leftIcon && <StyledIcon icon={leftIcon} />}

      <StyledInput
        type="text"
        value={timeValue}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onKeyPress={handleKeyPress}
        maxLength={12}
        style={{ '--size': 11 }}
      />
    </Container>
  );
};

export default React.memo(TimePicker);
