// vendors
import React, { useCallback } from 'react';
// import screenfull from "screenfull";
import { ButtonGroup, MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import 'styled-components/macro';
import { useMedia } from 'react-use';

// context
import { playbackRateOptions, useVideoPlayer } from '../VideoPlayer.context';

// utils
import { secondsToTimeLong } from '../utils/formatTime';

// styles
import {
  Container,
  Button,
  Timeline,
  VolumeWrapper,
  VolumeSliderWrapper,
  VolumeSlider,
  Wrapper,
  TimeStampWrapper,
  TimeStamp,
  TimeSeparator,
} from './Controls.styles';

import { modifier, useKeys } from '../../Shortcuts';
import { useMemo } from 'react';
import IconCaptions from '../../../Images/IconCaptions';
import { lessThanCondition } from '../../../Utils/mediaQuery';

const keyMap = {
  PLAY_PAUSE: {
    action: 'Pause/Play',
    combination: {
      modifiers: [modifier.SHIFT],
      key: 'Space',
    },
  },
  INCREASE_PLAYBACK_RATE: {
    action: 'Increase playback speed',
    combination: {
      modifiers: [modifier.SHIFT],
      key: 'ArrowUp',
    },
  },
  DECREASE_PLAYBACK_RATE: {
    action: 'Reduce playback speed',
    combination: {
      modifiers: [modifier.SHIFT],
      key: 'ArrowDown',
    },
  },
  SEEK_FORWARD: {
    action: 'Move forward 5 seconds',
    combination: {
      modifiers: [modifier.SHIFT],
      key: 'ArrowRight',
    },
  },
  SEEK_BACKWARD: {
    action: 'Go back 5 seconds',
    combination: {
      modifiers: [modifier.SHIFT],
      key: 'ArrowLeft',
    },
  },
};

const renderPlaybackRateOption = (rate, { handleClick, modifiers }) => {
  return (
    <MenuItem
      key={rate}
      text={rate}
      // label={rate}
      active={modifiers.active}
      onClick={handleClick}
    />
  );
};

const Controls = () => {
  const {
    // playerRef,
    isPlaying,
    toggleIsPlaying,
    setPlaybackRate,
    playbackRate,
    seekBackward,
    seekToPercentage,
    progress,
    duration,
    volume,
    setVolume,
    muted,
    setMuted,
    increasePlaybackRate,
    decreasePlaybackRate,
    seekForward,
    setHideCaption,
    hideCaption,
    fullscreenElement,
    handleFullScreen,
  } = useVideoPlayer();

  const small = useMedia(lessThanCondition(1280));

  const handleSeekForward = useCallback(() => seekForward(5), [seekForward]);

  const handleSeekBackward = useCallback(() => seekBackward(5), [seekBackward]);

  const handleSeekChange = useCallback(
    (value) => {
      // console.log(value);
      seekToPercentage(value / 100);
    },
    [seekToPercentage]
  );

  const handleVolumeChange = useCallback(
    (value) => {
      setVolume(value / 100);
    },
    [setVolume]
  );

  const handleMuted = useCallback(() => {
    setMuted(!muted);
  }, [muted, setMuted]);

  useMemo(() => {
    keyMap['PLAY_PAUSE'].handler = toggleIsPlaying;
    keyMap['INCREASE_PLAYBACK_RATE'].handler = increasePlaybackRate;
    keyMap['DECREASE_PLAYBACK_RATE'].handler = decreasePlaybackRate;
    keyMap['SEEK_FORWARD'].handler = handleSeekForward;
    keyMap['SEEK_BACKWARD'].handler = handleSeekBackward;
  }, [
    decreasePlaybackRate,
    handleSeekBackward,
    handleSeekForward,
    increasePlaybackRate,
    toggleIsPlaying,
  ]);

  useKeys(keyMap);

  // const handleFullscreen = useCallback(() => {
  //   screenfull.request(findDOMNode(playerRef.current));
  // }, [playerRef]);

  const labelRenderer = useCallback(
    (value) => {
      const currentTime = (duration / 100) * value;

      // return secondsToTimeShort(currentTime, duration);
      return secondsToTimeLong(currentTime, duration);
    },
    [duration]
  );

  const volumeIconStatus = useCallback(() => {
    if (volume === 0 || muted) return 'off';

    if (volume > 0.5) return 'up';

    return 'down';
  }, [volume, muted]);

  const handleCaptionDisplaying = useCallback(() => {
    setHideCaption((state) => !state);
    document.activeElement.blur();
  }, [setHideCaption]);

  const btnCaptionText = hideCaption
    ? 'Show subtitles/closed captions'
    : 'Hide subtitles/closed captions';

  return (
    <Container>
      <Wrapper>
        <div
          css={`
            grid-column: 1;
            grid-row: 1;
            margin: 0 1rem;
          `}
        >
          <Timeline
            value={progress.played * 100}
            max={100}
            labelStepSize={100}
            stepSize={0.1}
            labelRenderer={labelRenderer}
            onChange={handleSeekChange}
          />
        </div>

        <div
          css={`
            grid-column: 1;
            grid-row: 2;

            display: flex;
            justify-content: space-between;
            align-items: center;

            margin: 0 8px;
          `}
        >
          <ButtonGroup
            css={`
              margin-left: 5px;

              > * + * {
                margin-left: 10px;
              }
            `}
          >
            <Button
              icon={isPlaying ? 'pause' : 'play'}
              minimal
              onClick={toggleIsPlaying}
            />
            <Button minimal icon="step-backward" onClick={handleSeekBackward} />
            <Button
              minimal
              rightIcon="step-forward"
              onClick={handleSeekForward}
            />
            <VolumeWrapper>
              <Button
                minimal
                icon={`volume-${volumeIconStatus()}`}
                onClick={handleMuted}
              />

              <VolumeSliderWrapper id="volume-wrapper">
                <VolumeSlider
                  value={volume * 100}
                  max={100}
                  stepSize={1}
                  onChange={handleVolumeChange}
                />
              </VolumeSliderWrapper>
            </VolumeWrapper>

            <TimeStampWrapper>
              <TimeStamp>{`${labelRenderer(progress.played * 100)}`}</TimeStamp>
              <TimeSeparator>/</TimeSeparator>
              <TimeStamp>{`${labelRenderer(100)}`}</TimeStamp>
            </TimeStampWrapper>
          </ButtonGroup>

          <ButtonGroup
            css={`
              margin-right: 5px;
            `}
          >
            <Button
              minimal
              icon={<IconCaptions inverted={hideCaption ? false : true} />}
              text={small ? undefined : btnCaptionText}
              onClick={handleCaptionDisplaying}
            />

            <Select
              activeItem={playbackRate}
              items={playbackRateOptions}
              itemRenderer={renderPlaybackRateOption}
              filterable={false}
              itemsEqual={(a, b) => a === b}
              onItemSelect={setPlaybackRate}
            >
              <Button
                minimal
                icon="dashboard"
                text={small ? `(${playbackRate}x)` : `Speed (${playbackRate}x)`}
              />
            </Select>
            <Button
              minimal
              icon={fullscreenElement ? 'minimize' : 'fullscreen'}
              onClick={handleFullScreen}
            />
          </ButtonGroup>
        </div>
      </Wrapper>
    </Container>
  );
};

export default Controls;
