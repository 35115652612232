import { ADD_TOAST, REMOVE_TOAST, REMOVE_ALL_TOAST } from '../Actions';

const initialState = {
  toasts: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TOAST: {
      const newToast = {
        id: Date.now(),
        message: action.payload.message,
        timeout: action.payload.timeout || null,
        intent: action.payload.intent || 'primary',
        icon: action.payload.icon || '',
      };

      return {
        toasts: [...state.toasts, newToast],
      };
    }

    case REMOVE_TOAST: {
      return {
        toasts: state.toasts.filter(({ id }) => id !== action.payload.id),
      };
    }

    case REMOVE_ALL_TOAST: {
      return {
        toasts: [],
      };
    }

    default:
      return state;
  }
};

export default reducer;
