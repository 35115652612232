import React from 'react';
import { calculateWpm, getFullFps, secondsToFrameNumber } from '../States/Reducers/srtData';

const ShowErrorWithWpm = ({wpm, fps, startTime, endTime, index, lines, subtitles}) => {

  const cellDurationInSeconds = endTime?.value - startTime?.value;
  const maxWpm = 325;
  const nbWords = Math.round(lines.join(' ').length / 5);
  const nextSub = subtitles[index + 1];

  const calculateSuggestions = () => {
    let characterSuggestion = null;
    let durationSuggestion = null;
    let minDurationSuggestion = null;
    let maxDurationSuggestion = null;
    const maxDuration = 5;
    const minDuration = 1;

    if (wpm > maxWpm) {
      // const characterReduction = Math.ceil(
      //   (nbWords - maxWpm * (cellDurationInSeconds / 60)) * 5
      // );

      let nbCharacters = 0;

      lines.forEach((line) => {
        nbCharacters += line.length;
      });

      let maxWords = Math.floor((cellDurationInSeconds / 60) * maxWpm);
      let fullFps = null;
      let cellDurationInFrames = null;

      if (fps !== null) {
        fullFps = getFullFps(fps);
        maxWords = Math.floor(((secondsToFrameNumber(endTime?.value, fullFps) - secondsToFrameNumber(startTime?.value, fullFps)) / secondsToFrameNumber(60, fullFps)) * maxWpm);
        cellDurationInFrames = fps !== null ? secondsToFrameNumber(endTime?.value, fullFps)  - secondsToFrameNumber(startTime?.value, fullFps) : null;

      }


      const maxCharacters = maxWords * 5;

      let characterReduction = (nbCharacters - maxCharacters) -1;
      let durationIncreasePerWord = (1 / maxWpm - 1 / wpm) * (60);

      if (fps !== null) {
        durationIncreasePerWord = ((1 / maxWpm - 1 / wpm) * (secondsToFrameNumber(60, fullFps))) / fullFps;
      }
      const increasedDuration = durationIncreasePerWord * nbWords;


      if (cellDurationInSeconds + increasedDuration <= maxDuration || (cellDurationInFrames !== null && (cellDurationInFrames/fps) <= maxDuration)) {
        let evalDuration = true;
        if (nextSub) {
          const startTimeNextSub = nextSub.startTime?.value;
          if (startTimeNextSub === endTime?.value) {
            evalDuration = false;
          }
        }
        const totalDuration = cellDurationInSeconds + increasedDuration;
        let wpm2 = Math.ceil(nbWords / (totalDuration / 60));

        if (fps !== null) {
          wpm2 = calculateWpm(startTime?.value, endTime?.value + increasedDuration, subtitles[index].lines, fps);
        }
        if (wpm2 <= maxWpm && evalDuration) {
          durationSuggestion = increasedDuration;
        }
      }

      if (
        characterReduction <= characterSuggestion ||
        durationSuggestion === null
      ) {
        characterSuggestion = characterReduction;
        durationSuggestion = null;
      } else {
        characterSuggestion = null;
      }
    }

    // if (cellDurationInSeconds < minDuration) {
    //   minDurationSuggestion =
    //     'Min duration is 1 second this cell has: ' + Number(cellDurationInSeconds.toFixed(2)) + ' seconds';
    // }

    // if (cellDurationInSeconds > maxDuration) {
    //   maxDurationSuggestion =
    //     'Max duration is 5 seconds this cell has: ' + Number(cellDurationInSeconds.toFixed(2)) + ' seconds';
    // }

    return { characterSuggestion, durationSuggestion , minDurationSuggestion, maxDurationSuggestion};
  };

  const { characterSuggestion, durationSuggestion, minDurationSuggestion, maxDurationSuggestion} = calculateSuggestions();

  return (
    <div style={{ gridRow: '3 / span 1', gridColumn: '2' , display: 'inline-block'}}>
      {characterSuggestion && characterSuggestion > 0 && (
        <p style={{ color: 'red', fontSize: 10 }}>
          Reduce the cell by {characterSuggestion} characters
        </p>
      )}
      {durationSuggestion && (
        <p style={{ color: 'red', fontSize: 10 }}>
          Increase the duration by {durationSuggestion.toFixed(2)} seconds
        </p>
      )}
      {minDurationSuggestion && (
        <p style={{ color: 'red', fontSize: 10 }}>{minDurationSuggestion}</p>
      )}
      {maxDurationSuggestion && (
        <p style={{ color: 'red', fontSize: 10 }}>{maxDurationSuggestion}</p>
      )}
    </div>
  );
};

export default ShowErrorWithWpm;
