// vendors
import { Checkbox as BpCheckbox, Colors } from '@blueprintjs/core';
import styled, { css } from 'styled-components';

// components
import TimePicker from '../TimePicker';

export const activeStyle = css`
  background-color: rgb(235 241 245 / 24%);
`;

export const StyledCard = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  grid-template-rows: 1fr auto auto;
  grid-gap: 10px;

  height: 100%;

  /* margin: 0 6px; */
  padding: 0;
  padding: 8px ${({ $warning }) => ($warning ? '0' : '8px')} 8px 16px;

  border: 1px solid ${Colors.GRAY4};
  border-radius: 3px;

  background-color: ${Colors.WHITE};

  ${({ $warning }) =>
    $warning && `border: 1px solid hsl(var(--color-red-hsl-val));`}

  will-change: background-color, border-color, transform;
`;

export const SubtitleNumber = styled.div`
  font-size: 1.3em;
  padding-bottom: 0.5em;
  /* cursor: pointer; */
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;

  opacity: ${({ $active }) => ($active ? 0.95 : 0.35)};
`;

export const SubtitleWpm = styled.div`
  font-size: 1em;
  margin-left: 4em;
  padding-bottom: 0.5em;
  /* cursor: pointer; */
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;

`;

export const TimeContainer = styled.div`
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
`;

export const InputContainer = styled.div`
  grid-column: 2 / span 1;
  grid-row: 1 / span 2;

  display: grid;
  grid-template-rows: minmax(5px, auto) 1fr;
  grid-gap: 2px;
`;

export const ControlsContainer = styled.div`
  grid-column: 3 / span 1;
  grid-row: 1 / -1;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
`;

export const StatusBar = styled.div`
  grid-column: 4 / span 1;
  grid-row: 1 / -1;

  margin: -8px 0;
  /* background-color: #fddcda; */
  background-color: var(--color-red-hsl-light);
  color: #fff;
  text-align: center;

  display: flex;

  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
`;

export const StyledTimepicker = styled(TimePicker)`
  display: block;
  color: hsla(var(--color-gray-hsl-val) / 0.7);
  font-size: 0.85em;

  box-shadow: none;
`;

export const Highlighted = styled.span`
  /* color: ${Colors.RED4}; */
  color: hsl(var(--color-red-hsl-val));
  font-weight: bold;
  /* border-bottom: 1px solid ${Colors.RED4}; */
  border-bottom: 1px solid hsl(var(--color-red-hsl-val));
`;

export const Checkbox = styled(BpCheckbox)`
  margin: 0;
  white-space: nowrap;

  input,
  span {
    display: inline-block;
  }
`;
