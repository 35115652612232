// vendors
import React from 'react';
import { Spinner } from '@blueprintjs/core';

// styles
import { Container } from './PageLoader.styles';

const LoadingView = () => (
  <Container>
    <Spinner />
  </Container>
);

export default LoadingView;
