// vendors
import Joi from 'joi';

import { modifier } from './keys';

const keyMapSchema = Joi.object().pattern(
  Joi.string(),
  Joi.object({
    action: Joi.string().required(),
    combination: Joi.object({
      modifiers: Joi.array()
        .items(
          Joi.string().valid(
            modifier.ALT,
            modifier.CTRL,
            modifier.SHIFT,
            modifier.META
          )
        )
        .unique()
        .max(4),
      key: Joi.string(),
    })
      .min(1)
      .required(),
    handler: Joi.function(),
    customCombinationLabel: Joi.string(),
  })
);

export default keyMapSchema;
