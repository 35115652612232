// vendors
import React, { useCallback, useRef, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Virtuoso as List } from 'react-virtuoso';
import 'styled-components/macro';

// components
import { modifier, useKeys } from '../../../Components/Shortcuts';
import { useVideoPlayer } from '../../../Components/VideoPlayer';

// views
import RenderSubtitleCard from './RenderSubtitleCard';

const SubtitleListContainer = styled.div`
  max-height: 100vh;
  /* overflow-y: auto; */
`;

const Scroller = styled.div`
  > div {
    padding: 0.5em 1em 14px 0.25em;
  }
`;

const keyMap = {
  LINE_BREAK: {
    action: 'Line break',
    combination: {
      modifiers: [modifier.SHIFT],
      key: 'Enter',
    },
  },
  NEW_SUBTITLE: {
    action: 'Add Subtitle',
    combination: {
      key: 'Enter',
    },
  },
};

const SubtitleSection = () => {
  const listRef = useRef({});

  const subtitles = useSelector(
    (state) => state.srtData.present.data.subtitles
  );

  const fps = useSelector(
    (state) => state.fps
  );

  const isCaptionsForTv = useSelector(
    (state) => state.srtData.present.isCaptionsForTv
  );

  const isOneSentencePerCaption = useSelector(
    (state) => state.srtData.present.isOneSentencePerCaption
  );

  const isCustomerVersion = useSelector(
    (state) => state.srtData.present.iscustomerversion
  );

  //console.log(isCaptionsForTv);

  const meta = useSelector((state) => state.srtData.present.meta);

  // const currentTimeVal = useSelector(
  //   (state) => state.srtData.present.currentTime
  // );
  const { isPlaying, progress } = useVideoPlayer();

  // useEffect(() => {
  //   seekToSeconds(currentTimeVal);
  // }, [currentTimeVal]);

  // const isPlaying = useSelector((state) => state.media.isPlaying);

  // const [activeIndex, setActiveIndex] = React.useState(0);

  /**
   * Check when the card is active depending on the current time from global state.
   *
   * FIXME: Perhaps we can find a better way to do it but until then, we need to add or retract 0.0001
   * depending on the start and end time here in order to avoid overlapping time with the previous and next subtitle.
   **/
  const isActive = useCallback(
    (index) => {
      const current = subtitles[index];
      const endTime = subtitles[index + 1]
        ? subtitles[index + 1].startTime.value
        : progress.playedSeconds + 0.0001;

      return (
        current.startTime.value < progress.playedSeconds + 0.0001 &&
        progress.playedSeconds <= endTime - 0.0001
      );
    },
    [progress.playedSeconds, subtitles]
  );

  useKeys(keyMap);

  const activeIndex = useMemo(
    () => subtitles.findIndex((_, i) => isActive(i)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [progress.playedSeconds]
  );

  useEffect(() => {
    if (!listRef || !listRef.current || subtitles.length <= 0) {
      return;
    }

    if (isPlaying) {
      listRef.current.scrollToIndex({
        index: activeIndex,
        align: 'center',
        behavior: 'auto',
      });

      return;
    }

    listRef.current.scrollIntoView({
      index: activeIndex,
      behavior: 'auto',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  const RenderRow = useCallback(
    (index) => (
      <div
        css={`
          padding: 3px 6px;
          padding-bottom: ${index === subtitles.length - 1 && '1em'};
          will-change: transform;
        `}
      >
        <RenderSubtitleCard
          key={index}
          subtitle={{ ...subtitles[index], meta }}
          subtitles={subtitles}
          index={index}
          // isActive={isActive(index)}
          isActive={index === activeIndex}
          faded={isPlaying && index > activeIndex}
          fps={fps}
          isCaptionsForTv={isCaptionsForTv}
          isOneSentencePerCaption={isOneSentencePerCaption}
          isCustomerVersion={isCustomerVersion}
        />
      </div>
    ),
    [activeIndex, isPlaying, meta, subtitles, isCaptionsForTv, isCustomerVersion]
  );

  // useEffect(() => {
  //   if (navigator.userAgent.indexOf('Mac') !== -1) setIsMacOS(true);
  // }, []);

  return (
    <SubtitleListContainer>
      <List
        ref={listRef}
        style={{
          height: '100%',
          transform: 'translate3d(0, 0, 0)',
          willChange: 'transform',
        }}
        data={subtitles}
        itemContent={RenderRow}
        components={{ Scroller }}
      />
    </SubtitleListContainer>
  );
};

export default SubtitleSection;
