import styled from 'styled-components';

export const Wrapper = styled.div`
  color: var(--color-gray-hex);
  font-size: 0.7rem;
`;

export const Title = styled.strong`
  /* font-size: 0.75rem; */
`;

export const Content = styled.p`
  margin: 0;
  /* font-size: 0.7rem; */

  strong {
    color: hsl(var(--color-green-hs-val) 30%);
  }
`;
