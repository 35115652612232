// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip2 } from '@blueprintjs/popover2';

// styles
import { Content, Title, Wrapper } from './Tooltip.styles';

const TooltipContent = ({ title, content }) => (
  <Wrapper>
    {title && <Title>{title}</Title>}

    <Content dangerouslySetInnerHTML={{ __html: content }} />
  </Wrapper>
);

const Tooltip = ({
  title,
  content,
  position,
  minimal,
  usePortal,
  isOpen,
  children,
}) => (
  <Tooltip2
    content={<TooltipContent title={title} content={content} />}
    placement={position}
    minimal={minimal}
    usePortal={usePortal}
    isOpen={isOpen}
  >
    {children}
  </Tooltip2>
);

Tooltip.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string.isRequired,
  position: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  minimal: PropTypes.bool,
  usePortal: PropTypes.bool,
  isOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
Tooltip.defaultProps = {
  title: null,
  position: 'right',
  minimal: false,
  usePortal: false,
  isOpen: undefined,
  // isOpen: true,
};

export default Tooltip;
