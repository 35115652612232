import React from 'react';
import Waveform from '../Components/Waveform';

import styled from 'styled-components';

const Container = styled.div`
  background: #ced9e0;
  width: 100%;
  height: var(--sst-timeline-max-height);
`;

function Timeline(props) {
  return (
    <Container {...props}>
      <Waveform />
    </Container>
  );
}

export default Timeline;
