import styled, { css } from 'styled-components';
import { Checkbox as BpCheckbox, Colors } from '@blueprintjs/core';

export const bottomLinearGradientStyles = css`
  position: absolute;
  left: 0;
  bottom: -20px;
  width: 100%;
  height: 20px;
  background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0));
  content: '';
`;

export const topLinearGradientStyles = css`
  position: absolute;
  left: 0;
  top: -20px;
  width: 100%;
  height: 20px;
  background: linear-gradient(to top, white, rgba(255, 255, 255, 0));
  content: '';
`;

export const headingStyles = css`
  position: relative;
  color: hsl(var(--color-red-hs-val) 35%);
  margin: 0;
  padding-bottom: 16px;

  > :last-child {
    margin-left: 12px;
  }

  ::after {
    ${bottomLinearGradientStyles}
  }
`;

export const Highlighted = styled.span`
  border-bottom: 1px solid hsl(var(--color-red-hsl-val));

  color: hsl(var(--color-red-hsl-val));
  font-weight: bold;
`;

export const Checkbox = styled(BpCheckbox)`
  margin: 0;
  white-space: nowrap;

  input,
  span {
    display: inline-block;
  }
`;

export const IndexIndicator = styled.div`
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
  color: ${Colors.GRAY3};
`;

export const List = styled.ul`
  list-style: none;
  padding: 12px 0;
  margin: 0;
  overflow-y: auto;
  max-height: 192px;

  @media screen and (min-height: 580px) {
    max-height: 225px;
  }

  @media screen and (min-height: 832px) {
    max-height: 450px;
  }

  > * + * {
    border-top: 1px solid ${Colors.GRAY5};
    margin-top: 24px;
    padding-top: 24px;
  }
`;

export const BottomWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 20px;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;

  ::before {
    ${topLinearGradientStyles}
  }
`;
