// vendors
import { combineReducers } from 'redux';
// import undoable, { excludeAction } from 'redux-undo';
import undoable from 'easy-redux-undo';

// reducers
import srtData from './srtData';
import media from './media';
import toasts from './toasts';

// actions
import {
  CURRENTLY_SELECTED,
  CURRENT_TIME,
  FETCH_SRT_DATA_BEGIN,
  FETCH_SRT_DATA_FAILURE,
  FETCH_SRT_DATA_SUCCESS,
  RESET_CARET_POSITION,
  RESET_EDIT_SINCE_LAST_SAVED,
  SAVE_SRT_DATA_BEGIN,
  SAVE_SRT_DATA_FAILURE,
  SAVE_SRT_DATA_SUCCESS,
} from '../Actions';

export default combineReducers({
  srtData: undoable(srtData, {
    maxHistory: 10,
    exclude: [
      CURRENT_TIME,
      CURRENTLY_SELECTED,
      RESET_CARET_POSITION,
      RESET_EDIT_SINCE_LAST_SAVED,
      FETCH_SRT_DATA_BEGIN,
      FETCH_SRT_DATA_SUCCESS,
      FETCH_SRT_DATA_FAILURE,
      SAVE_SRT_DATA_BEGIN,
      SAVE_SRT_DATA_SUCCESS,
      SAVE_SRT_DATA_FAILURE,
    ],
  }),
  media,
  toasts,
});
