import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { FullScreen } from "react-full-screen";

export const Container = styled(FullScreen)`
  /* position: relative; */
  position: relative;
  /* display: flex;
  justify-content: center;
  min-width: 480px;
  min-height: 100px;
  height: 100%;*/
  overflow: hidden;
  /* background-color: var(--color-black); */
`;

export const PlayerWrapper = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
  /* min-width: 500px; */
  max-height: calc(
    100vh - var(--sst-titlebar-height) - var(--sst-timeline-height) - 98px -
      0.5em
  );
`;

export const StyledReactPlayer = styled(ReactPlayer)`
  width: 100%;
  height: 100%;
`;

export const CaptionTrackContainer = styled.div`
  position: absolute;
  bottom: 60px;
  left: 5%;
  width: 90%;
  pointer-events: none;

  max-height: 80%;
  overflow: hidden;

  text-align: center;
`;

export const CaptionTrack = styled.span`
  pointer-events: none;
  padding: ${({ $hasTrack }) => ($hasTrack ? `0 2px 0.15em` : 0)};

  background: rgb(0 0 0 / 0.8);

  color: white;
  font-size: clamp(1rem, 2vw, 2.5rem);
  line-height: 1.5;
  white-space: pre-wrap;
`;
