// vendors
import React from 'react';
import Titlebar from '../Titlebar';
import { Container, Wrapper } from './Layout.styles';

const Layout = ({ children, onSave, onShare }) => (
  <Container>
    <Titlebar onSave={onSave} onShare={onShare} />

    <Wrapper>{children}</Wrapper>
  </Container>
);

export default Layout;
