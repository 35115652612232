// vendors
import React from 'react';
import { Button } from '@blueprintjs/core';

// contexts
import { useShortcuts } from './Shortcuts.context';

// styles
import {
  Dialog,
  Container,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
} from './Shortcuts.styles';

const Shortcuts = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleButtonClick = React.useCallback(
    () => setIsOpen(!isOpen),
    [isOpen]
  );
  const handleClose = React.useCallback(() => setIsOpen(false), []);

  const { getUIShortcuts } = useShortcuts();

  const shortcuts = getUIShortcuts();

  return (
    <>
      <Button
        text="Shortcuts"
        icon="key-command"
        onClick={handleButtonClick}
        outlined
      />
      <Dialog
        title="Keyboard shortcuts"
        isOpen={isOpen}
        onClose={handleClose}
        canEscapeKeyClose
        canOutsideClickClose
        enforceFocus
        shouldReturnFocusOnClose
        usePortal
      >
        <Container>
          <Table>
            <TableBody>
              {shortcuts.map((shortcut) => (
                <TableRow key={shortcut.id}>
                  <TableHead>{shortcut.action}</TableHead>
                  <TableCell
                    dangerouslySetInnerHTML={{ __html: shortcut.combination }}
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Container>
      </Dialog>
    </>
  );
};

export default Shortcuts;
