import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: var(--sst-titlebar-height) calc(
      100vh - var(--sst-titlebar-height)
    );
  height: 100vh;
`;

export const Wrapper = styled.div`
  grid-column: 1 / -1;
  grid-row: 2 / span 1;
`;
