// vendors
import React, {
  useCallback,
  useEffect,
  useContext,
  useMemo,
  useState,
} from 'react';

// utils
import {
  keyCodeMap,
  modifierKeyMap,
  UIKeyCommonMap,
  UIKeyMapFr,
} from './Utils/keys';
import { findDuplicates } from './Utils/validation';
import keyMapSchema from './Utils/schema';

export const ShortcutsContext = React.createContext();

export const useShortcuts = () => useContext(ShortcutsContext);

export const ShortcutsProvider = ({ children }) => {
  const [shortcuts, setShortcuts] = useState({});

  const validateKeyMap = useCallback(async (keyMap) => {
    try {
      await keyMapSchema.validateAsync(keyMap);
    } catch (err) {
      throw new Error(err);
    }
  }, []);

  const subscribeKeys = useCallback(
    (keyMap) => {
      validateKeyMap(keyMap);

      setShortcuts((prev) => {
        findDuplicates(keyMap, prev);

        return { ...prev, ...keyMap };
      });

      return {
        unsubscribeKeys() {
          const updatedShortcuts = Object.entries(shortcuts)
            .filter((f) => Object.keys(keyMap).every((g) => f[0] !== g))
            .reduce((acc, [k, v]) => {
              acc[k] = v;
              return acc;
            }, {});

          setShortcuts({ ...updatedShortcuts });
        },
      };
    },
    [shortcuts]
  );

  const getUIShortcuts = useCallback(() => {
    return Object.entries(shortcuts).map((shortcut) => {
      const {
        action,
        combination: { modifiers, key },
        customCombinationLabel,
      } = shortcut[1];

      const UIModifiers = modifiers
        ? key
          ? `${modifiers.join(' + ')} + `
          : `${modifiers.join(' + ')}`
        : '';

      const UIKey = UIKeyCommonMap[key] || UIKeyMapFr[key] || key || '';
      const combination = `${UIModifiers}${UIKey}`;

      return {
        id: shortcut[0],
        action,
        combination: customCombinationLabel || combination,
      };
    });
  }, [shortcuts]);

  const getModifiersKey = useCallback((modifiers) => {
    if (!modifiers || modifiers.length === 0) return;

    return modifiers.map((modifier) => modifierKeyMap[modifier]);
  }, []);

  const handleKeyDown = useCallback(
    (event) => {
      if (!event) return;

      const isInput =
        event.target.closest('input') || event.target.closest('textarea');

      if (isInput && !(event.shiftKey && event.code === 'Space')) return;

      if (event.shiftKey && event.code === 'Space') event.preventDefault();

      Object.values(shortcuts)
        .filter((s) => s.handler)
        .every((shortcut) => {
          const {
            combination: { modifiers, key },
            handler,
          } = shortcut;

          const modifiersKey = getModifiersKey(modifiers);

          const hasModifiers = modifiersKey && modifiersKey.length > 0;
          const keyPressed =
            event.code === key ||
            event.code === keyCodeMap[key.toLowerCase()] ||
            event.key === key;

          if (hasModifiers) {
            const modifiersKeyPressed = modifiersKey.every((m) => event[m]);

            const noOtherModifiersPressed = Object.values(modifierKeyMap)
              .filter((m) => !modifiersKey.includes(m))
              .every((m) => event[m] === false);

            const hasModifiersWithKeyPressed =
              modifiersKeyPressed &&
              noOtherModifiersPressed &&
              key &&
              keyPressed;

            const hasModifiersOnlyPressed =
              !key && modifiersKeyPressed && noOtherModifiersPressed;

            if (hasModifiersWithKeyPressed || hasModifiersOnlyPressed) {
              handler(event);

              return true;
            }

            return true;
          }

          const noModifiersPressed = Object.values(modifierKeyMap).every(
            (m) => event[m] === false
          );
          if (noModifiersPressed && keyPressed) {
            handler(event);
          }

          return true;
        });
    },
    [shortcuts]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  const value = useMemo(
    () => ({ subscribeKeys, getUIShortcuts }),
    [subscribeKeys, getUIShortcuts]
  );

  return (
    <ShortcutsContext.Provider value={value}>
      {children}
    </ShortcutsContext.Provider>
  );
};

export const withShortcutsProvider = (WrappedComponent) => (props) =>
  (
    <ShortcutsProvider>
      <WrappedComponent {...props} />
    </ShortcutsProvider>
  );
