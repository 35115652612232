// vendors
import { Icon } from '@blueprintjs/core';
import styled, { css } from 'styled-components';

const errorStyle = css`
  border-color: var(--color-red-hex);
`;

export const Container = styled.div`
  /* appearance: textfield;
  -moz-appearance: textfield; */

  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: center;
  background: transparent;
  /* border: 1px solid transparent; */
  border-radius: 3px;
  padding: 2px;

  /* ${({ focus }) =>
    focus &&
    `
    box-shadow: 0 0 0 1px #137cbd,0 0 0 3px rgba(19,124,189,.3),inset 0 1px 1px rgba(16,22,26,.2);
  `} */
`;

export const StyledInput = styled.input`
  --timestamp-input-border: 2px;
  appearance: none;
  /* -moz-appearance: textfield; */
  /* border: 0; */
  width: calc(var(--size) * 1ch + var(--timestamp-input-border));
  padding-left: 0.125em;
  padding-right: 0.125em;

  color: inherit;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 1.18em;

  background-color: transparent;
  border: var(--timestamp-input-border) solid transparent;
  border-radius: 3px;

  :focus {
    border-color: var(--color-blue-hex);
    background-color: white;

    color: hsl(var(--color-gray-hsl-val));
  }

  /* Chrome, Safari, Edge, Opera */
  /* ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  } */

  /* Firefox */
  /* &[type='number'] {
    -moz-appearance: textfield;
  } */

  /* ${({ error }) => error && errorStyle}; */
`;

export const StyledSeperator = styled.span``;

export const StyledIcon = styled(Icon)`
  align-self: center;
`;
